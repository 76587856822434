/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./schema";
type GeneratedQuery<InputType, OutputType> = string & {
    __generatedQueryInput: InputType;
    __generatedQueryOutput: OutputType;
};

export const listNotificationsByUser =
    /* GraphQL */ `query ListNotificationsByUser($input: ListNotificationsByUserInput!) {
  listNotificationsByUser(input: $input) {
    items {
      userId
      notificationId
      read
      created
      color
      image
      title
      message
      action
      type
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListNotificationsByUserQueryVariables, APITypes.ListNotificationsByUserQuery>;
export const getNote = /* GraphQL */ `query GetNote($input: GetNoteInput!) {
  getNote(input: $input) {
    __typename
    userId
    noteId
    content
    hash
    public
    draft
    password
    summary
    title
    trash
    created
    updated
    noteCode
    file
    folderId
    flashcardSetId
    mediaId
    importType
    subject
    views
    verified
    classPublic
    schoolId
    grade
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    classId
    addedAt
    sections
    lock
    partial
    xpTracked
    isAI
    aiTool
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    neverPublic
    deadline
  }
}
` as GeneratedQuery<APITypes.GetNoteQueryVariables, APITypes.GetNoteQuery>;
export const getNoteV2 = /* GraphQL */ `query GetNoteV2($input: GetNoteInput!) {
  getNoteV2(input: $input) {
    item {
      __typename
      userId
      noteId
      content
      hash
      public
      draft
      password
      summary
      title
      trash
      created
      updated
      noteCode
      file
      folderId
      flashcardSetId
      mediaId
      importType
      subject
      views
      verified
      classPublic
      schoolId
      grade
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      classId
      addedAt
      sections
      lock
      partial
      xpTracked
      isAI
      aiTool
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      neverPublic
      deadline
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedQuery<APITypes.GetNoteV2QueryVariables, APITypes.GetNoteV2Query>;
export const getMedia = /* GraphQL */ `query GetMedia($input: GetMediaInput!) {
  getMedia(input: $input) {
    __typename
    userId
    mediaId
    created
    updated
    title
    description
    password
    public
    noteId
    flashcardSetId
    folderId
    trash
    type
    fileType
    pages
    length
    bucket
    embedUrl
    embedCommands
    subject
    topic
    exam_v2
    examUnit
    examSection
    tags
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    verified
    transcript
    schoolId
    grade
    views
    jwt
    classId
    addedAt
    sections
    partial
    xpTracked
    isAI
  }
}
` as GeneratedQuery<APITypes.GetMediaQueryVariables, APITypes.GetMediaQuery>;
export const getMediaV2 = /* GraphQL */ `query GetMediaV2($input: GetMediaInput!) {
  getMediaV2(input: $input) {
    item {
      __typename
      userId
      mediaId
      created
      updated
      title
      description
      password
      public
      noteId
      flashcardSetId
      folderId
      trash
      type
      fileType
      pages
      length
      bucket
      embedUrl
      embedCommands
      subject
      topic
      exam_v2
      examUnit
      examSection
      tags
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      verified
      transcript
      schoolId
      grade
      views
      jwt
      classId
      addedAt
      sections
      partial
      xpTracked
      isAI
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedQuery<APITypes.GetMediaV2QueryVariables, APITypes.GetMediaV2Query>;
export const listMedia = /* GraphQL */ `query ListMedia($input: ListMediaInput!) {
  listMedia(input: $input) {
    items {
      __typename
      userId
      mediaId
      created
      updated
      title
      description
      password
      public
      noteId
      flashcardSetId
      folderId
      trash
      type
      fileType
      pages
      length
      bucket
      embedUrl
      embedCommands
      subject
      topic
      exam_v2
      examUnit
      examSection
      tags
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      verified
      transcript
      schoolId
      grade
      views
      jwt
      classId
      addedAt
      sections
      partial
      xpTracked
      isAI
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListMediaQueryVariables, APITypes.ListMediaQuery>;
export const listNotes = /* GraphQL */ `query ListNotes($input: ListNoteInput!) {
  listNotes(input: $input) {
    items {
      __typename
      userId
      noteId
      content
      hash
      public
      draft
      password
      summary
      title
      trash
      created
      updated
      noteCode
      file
      folderId
      flashcardSetId
      mediaId
      importType
      subject
      views
      verified
      classPublic
      schoolId
      grade
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      classId
      addedAt
      sections
      lock
      partial
      xpTracked
      isAI
      aiTool
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      neverPublic
      deadline
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListNotesQueryVariables, APITypes.ListNotesQuery>;
export const getFlashcardSet = /* GraphQL */ `query GetFlashcardSet($input: GetFlashcardSetInput!) {
  getFlashcardSet(input: $input) {
    __typename
    userId
    flashcardSetId
    sort
    noteId
    mediaId
    position
    title
    description
    public
    password
    trash
    draft
    starred
    size
    studyFrom
    created
    updated
    quizletUrl
    answerSide
    shuffled
    flashcards {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
      termRecordedAudio
      definitionRecordedAudio
      mcOptions
      termCustomHint
      definitionCustomHint
    }
    termLanguage
    definitionLanguage
    relatedNotes
    order
    flashcardSetCode
    views
    classPublic
    subject
    verified
    schoolId
    grade
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    folderId
    rating
    ratingCount
    classId
    addedAt
    sections
    lock
    partial
    xpTracked
    studySettings {
      blockedStudyModes
      settings {
        LEARN {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          locked
          grid
          starred
          length
        }
        REVIEW {
          locked
          questionTypes
          answerSide
          starred
          position
          shuffled
          fuzzy
          reType
        }
      }
    }
    isAI
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    deadline
  }
}
` as GeneratedQuery<APITypes.GetFlashcardSetQueryVariables, APITypes.GetFlashcardSetQuery>;
export const listFlashcardSetByUser =
    /* GraphQL */ `query ListFlashcardSetByUser($input: ListFlashcardSetByUserInput!) {
  listFlashcardSetByUser(input: $input) {
    items {
      __typename
      userId
      flashcardSetId
      sort
      noteId
      mediaId
      position
      title
      description
      public
      password
      trash
      draft
      starred
      size
      studyFrom
      created
      updated
      quizletUrl
      answerSide
      shuffled
      flashcards {
        flashcardId
        flashcardSetId
        userId
        term
        termAudio
        definition
        definitionAudio
        distractorIds
        questionType
        isCorrect
        draft
        trash
        disabled
        image
        secondaryImage
        edited
        created
        updated
        twoSided
        schedule
        quality
        termRecordedAudio
        definitionRecordedAudio
        mcOptions
        termCustomHint
        definitionCustomHint
      }
      termLanguage
      definitionLanguage
      relatedNotes
      order
      flashcardSetCode
      views
      classPublic
      subject
      verified
      schoolId
      grade
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      folderId
      rating
      ratingCount
      classId
      addedAt
      sections
      lock
      partial
      xpTracked
      studySettings {
        blockedStudyModes
        settings {
          LEARN {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            reType
            length
            override
          }
          TEST {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            length
          }
          SPACED {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            cardsPerSession
            intervals
          }
          MATCH {
            locked
            grid
            starred
            length
          }
          REVIEW {
            locked
            questionTypes
            answerSide
            starred
            position
            shuffled
            fuzzy
            reType
          }
        }
      }
      isAI
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      deadline
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFlashcardSetByUserQueryVariables, APITypes.ListFlashcardSetByUserQuery>;
export const listFlashcardSetByNote =
    /* GraphQL */ `query ListFlashcardSetByNote($input: ListFlashcardSetByNoteInput!) {
  listFlashcardSetByNote(input: $input) {
    items {
      __typename
      userId
      flashcardSetId
      sort
      noteId
      mediaId
      position
      title
      description
      public
      password
      trash
      draft
      starred
      size
      studyFrom
      created
      updated
      quizletUrl
      answerSide
      shuffled
      flashcards {
        flashcardId
        flashcardSetId
        userId
        term
        termAudio
        definition
        definitionAudio
        distractorIds
        questionType
        isCorrect
        draft
        trash
        disabled
        image
        secondaryImage
        edited
        created
        updated
        twoSided
        schedule
        quality
        termRecordedAudio
        definitionRecordedAudio
        mcOptions
        termCustomHint
        definitionCustomHint
      }
      termLanguage
      definitionLanguage
      relatedNotes
      order
      flashcardSetCode
      views
      classPublic
      subject
      verified
      schoolId
      grade
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      folderId
      rating
      ratingCount
      classId
      addedAt
      sections
      lock
      partial
      xpTracked
      studySettings {
        blockedStudyModes
        settings {
          LEARN {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            reType
            length
            override
          }
          TEST {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            length
          }
          SPACED {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            cardsPerSession
            intervals
          }
          MATCH {
            locked
            grid
            starred
            length
          }
          REVIEW {
            locked
            questionTypes
            answerSide
            starred
            position
            shuffled
            fuzzy
            reType
          }
        }
      }
      isAI
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      deadline
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFlashcardSetByNoteQueryVariables, APITypes.ListFlashcardSetByNoteQuery>;
export const listFlashcardsByFlashcardSet =
    /* GraphQL */ `query ListFlashcardsByFlashcardSet($input: ListFlashcardsByFlashcardSetInput!) {
  listFlashcardsByFlashcardSet(input: $input) {
    items {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
      termRecordedAudio
      definitionRecordedAudio
      mcOptions
      termCustomHint
      definitionCustomHint
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFlashcardsByFlashcardSetQueryVariables, APITypes.ListFlashcardsByFlashcardSetQuery>;
export const listFollowing = /* GraphQL */ `query ListFollowing($input: ListFollowingInput!) {
  listFollowing(input: $input) {
    items {
      userId
      pictureUrl
      Name
      accountType
      username
      Email
      level
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFollowingQueryVariables, APITypes.ListFollowingQuery>;
export const listFollowers = /* GraphQL */ `query ListFollowers($input: ListFollowersInput!) {
  listFollowers(input: $input) {
    items {
      userId
      pictureUrl
      Name
      accountType
      username
      Email
      level
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFollowersQueryVariables, APITypes.ListFollowersQuery>;
export const listExamFRQs = /* GraphQL */ `query ListExamFRQs($input: ListExamFRQsInput!) {
  listExamFRQs(input: $input) {
    items {
      exam
      questionId
      difficulty
      question
      rating
      ratingCount
      examUnit
      examSection
      noteId
      flashcardSetId
      subject
      topic
      created
      updated
      url
      prompt
      stimuli
      name
      parts {
        question
        rating
        ratingCount
        stimuli
        prefill
      }
      rubric {
        items {
          guidelines
          points
        }
      }
      calc
      starred
      frqType
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListExamFRQsQueryVariables, APITypes.ListExamFRQsQuery>;
export const listSubjects = /* GraphQL */ `query ListSubjects($input: ListSubjectsInput) {
  listSubjects(input: $input) {
    items {
      subject
      picture
      color
      category
      order
      topics
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListSubjectsQueryVariables, APITypes.ListSubjectsQuery>;
export const listBanners = /* GraphQL */ `query ListBanners {
  listBanners {
    items {
      bannerId
      type
      message
      startTime
      endTime
      href
      promoCode
    }
  }
}
` as GeneratedQuery<APITypes.ListBannersQueryVariables, APITypes.ListBannersQuery>;
export const listExams = /* GraphQL */ `query ListExams($input: ListExamsInput) {
  listExams(input: $input) {
    items {
      type
      name
      description
      unitLabel
      sectionLabel
      baseLink
      image
      category
      color
      date
      time
      index
      noteId
      flashcardSetId
      discord {
        name
        link
        image
      }
      youtube {
        name
        link
        image
      }
      units {
        name
        image
        noteId
        flashcardSetId
        sections
      }
      crams {
        name
        image
        noteId
        flashcardSetId
        sections
      }
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListExamsQueryVariables, APITypes.ListExamsQuery>;
export const isFollowing = /* GraphQL */ `query IsFollowing($input: FollowInput!) {
  isFollowing(input: $input)
}
` as GeneratedQuery<APITypes.IsFollowingQueryVariables, APITypes.IsFollowingQuery>;
export const getCurrentUser = /* GraphQL */ `query GetCurrentUser($input: GetCurrentUserInput) {
  getCurrentUser(input: $input) {
    Email
    org
    accountType
    ID
    elID
    clID
    isSSOChecked
    timeZone
    referral
    Name
    pictureUrl
    username
    views
    birthday
    phone
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      resumes_at
      interval
      status
      platform
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      frq
      cost
      mSets
      mNotes
      tools
      assignments
      scans
      aiV
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
    signInTypes
    orgPlanType
    orgPlanCreated
    lastOrgPlanUpdated
    favTools
    lastStreakDate
    xpSyncDate
    xp
    level
    streak
    longPauseEnd
    coins
    featuredBadges
    records {
      xpEarned
      longestStreak
      badgesEarned
      coinsEarned
    }
    inventory {
      freezes
      revivals
      pauses
      icon
      features
      gamifySubExpiry
      gamifySubStoreProduct
    }
    gameBlock
    lastAdWatch
    lastTimeZoneChange
    notifSettings {
      emailSettings {
        disabled
        tokens
        reminderTime
      }
      pushSettings {
        disabled
        tokens
        reminderTime
      }
      gamifySettings {
        level
        streak
        badges
      }
    }
    stoppedNotifications
    inactiveUser
    promoCodes {
      code
      category
    }
    sentPromotions
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    orgRequests
    orgReqTime
    emailsSent
    lastSent
    isSidebarExpanded
  }
}
` as GeneratedQuery<APITypes.GetCurrentUserQueryVariables, APITypes.GetCurrentUserQuery>;
export const getCurrentUserAndOrganization =
    /* GraphQL */ `query GetCurrentUserAndOrganization($input: GetCurrentUserInput) {
  getCurrentUserAndOrganization(input: $input) {
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    organization {
      org
      schoolId
      created
      updated
      name
      pictureUrl
      country
      state
      files {
        url
        name
      }
      students
      teachers
      syncTimestamp
      isDirty
      paywall {
        count
        lastSent
      }
      aliases
      stage
      referrals {
        userId
        created
        sent
        type
        email
      }
      dpa {
        name
        email
        link
        type
      }
      admins {
        userId
        schoolId
      }
      pendingAdmins {
        Email
        schoolId
      }
      settings {
        schoolId
        sharing
        subsAllowed
        ai
      }
      schoolsSettings {
        schoolId
        sharing
        subsAllowed
        ai
      }
      verifiedSchools
      subscriptions {
        schoolId
        created
        current_period_end
        studentPlan
        teacherPlan
        studentsPaidFor
        teachersPaidFor
        paidStudents
        paidTeachers
        autoSubsStudent
        autoSubsTeacher
      }
      customerId
      provider
      elDistrictId
      elIntegrationId
    }
  }
}
` as GeneratedQuery<APITypes.GetCurrentUserAndOrganizationQueryVariables, APITypes.GetCurrentUserAndOrganizationQuery>;
export const listBookmarkByUserId = /* GraphQL */ `query ListBookmarkByUserId($input: ListBookmarkByUserIdInput!) {
  listBookmarkByUserId(input: $input) {
    items {
      userId
      ID
      type
      time
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListBookmarkByUserIdQueryVariables, APITypes.ListBookmarkByUserIdQuery>;
export const isBookmarked = /* GraphQL */ `query IsBookmarked($input: IsBookmarkInput!) {
  isBookmarked(input: $input)
}
` as GeneratedQuery<APITypes.IsBookmarkedQueryVariables, APITypes.IsBookmarkedQuery>;
export const isUsernameAvailable = /* GraphQL */ `query IsUsernameAvailable($input: IsUsernameAvailableInput!) {
  isUsernameAvailable(input: $input)
}
` as GeneratedQuery<APITypes.IsUsernameAvailableQueryVariables, APITypes.IsUsernameAvailableQuery>;
export const ESQueryFull = /* GraphQL */ `query ESQueryFull($input: ESQueryFullInput!) {
  ESQueryFull(input: $input) {
    result
  }
}
` as GeneratedQuery<APITypes.ESQueryFullQueryVariables, APITypes.ESQueryFullQuery>;
export const listFoldersByUser = /* GraphQL */ `query ListFoldersByUser($input: ListFoldersByUserInput!) {
  listFoldersByUser(input: $input) {
    items {
      __typename
      userId
      folderId
      parentId
      name
      color
      description
      created
      updated
      notesCount
      trash
      public
      password
      icon {
        emoji
        skinTone
      }
      classId
      addedAt
      sections
      partial
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFoldersByUserQueryVariables, APITypes.ListFoldersByUserQuery>;
export const listFoldersByParent = /* GraphQL */ `query ListFoldersByParent($input: ListFoldersByParentInput!) {
  listFoldersByParent(input: $input) {
    items {
      __typename
      userId
      folderId
      parentId
      name
      color
      description
      created
      updated
      notesCount
      trash
      public
      password
      icon {
        emoji
        skinTone
      }
      classId
      addedAt
      sections
      partial
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFoldersByParentQueryVariables, APITypes.ListFoldersByParentQuery>;
export const listNotesByFolder = /* GraphQL */ `query ListNotesByFolder($input: ListNotesByFolderInput!) {
  listNotesByFolder(input: $input) {
    items {
      __typename
      userId
      noteId
      content
      hash
      public
      draft
      password
      summary
      title
      trash
      created
      updated
      noteCode
      file
      folderId
      flashcardSetId
      mediaId
      importType
      subject
      views
      verified
      classPublic
      schoolId
      grade
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      classId
      addedAt
      sections
      lock
      partial
      xpTracked
      isAI
      aiTool
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      neverPublic
      deadline
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListNotesByFolderQueryVariables, APITypes.ListNotesByFolderQuery>;
export const listFlashcardSetsByFolder =
    /* GraphQL */ `query ListFlashcardSetsByFolder($input: ListFlashcardSetsByFolderInput!) {
  listFlashcardSetsByFolder(input: $input) {
    items {
      __typename
      userId
      flashcardSetId
      sort
      noteId
      mediaId
      position
      title
      description
      public
      password
      trash
      draft
      starred
      size
      studyFrom
      created
      updated
      quizletUrl
      answerSide
      shuffled
      flashcards {
        flashcardId
        flashcardSetId
        userId
        term
        termAudio
        definition
        definitionAudio
        distractorIds
        questionType
        isCorrect
        draft
        trash
        disabled
        image
        secondaryImage
        edited
        created
        updated
        twoSided
        schedule
        quality
        termRecordedAudio
        definitionRecordedAudio
        mcOptions
        termCustomHint
        definitionCustomHint
      }
      termLanguage
      definitionLanguage
      relatedNotes
      order
      flashcardSetCode
      views
      classPublic
      subject
      verified
      schoolId
      grade
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      folderId
      rating
      ratingCount
      classId
      addedAt
      sections
      lock
      partial
      xpTracked
      studySettings {
        blockedStudyModes
        settings {
          LEARN {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            reType
            length
            override
          }
          TEST {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            length
          }
          SPACED {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            cardsPerSession
            intervals
          }
          MATCH {
            locked
            grid
            starred
            length
          }
          REVIEW {
            locked
            questionTypes
            answerSide
            starred
            position
            shuffled
            fuzzy
            reType
          }
        }
      }
      isAI
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      deadline
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFlashcardSetsByFolderQueryVariables, APITypes.ListFlashcardSetsByFolderQuery>;
export const listMediaByFolder = /* GraphQL */ `query ListMediaByFolder($input: ListMediaByFolderInput!) {
  listMediaByFolder(input: $input) {
    items {
      __typename
      userId
      mediaId
      created
      updated
      title
      description
      password
      public
      noteId
      flashcardSetId
      folderId
      trash
      type
      fileType
      pages
      length
      bucket
      embedUrl
      embedCommands
      subject
      topic
      exam_v2
      examUnit
      examSection
      tags
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      verified
      transcript
      schoolId
      grade
      views
      jwt
      classId
      addedAt
      sections
      partial
      xpTracked
      isAI
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListMediaByFolderQueryVariables, APITypes.ListMediaByFolderQuery>;
export const getFolder = /* GraphQL */ `query GetFolder($input: GetFolderInput!) {
  getFolder(input: $input) {
    __typename
    userId
    folderId
    parentId
    name
    color
    description
    created
    updated
    notesCount
    trash
    public
    password
    icon {
      emoji
      skinTone
    }
    classId
    addedAt
    sections
    partial
  }
}
` as GeneratedQuery<APITypes.GetFolderQueryVariables, APITypes.GetFolderQuery>;
export const getRating = /* GraphQL */ `query GetRating($input: GetRatingInput!) {
  getRating(input: $input) {
    noteId
    userId
    noteOwnerId
    message
    rating
    type
  }
}
` as GeneratedQuery<APITypes.GetRatingQueryVariables, APITypes.GetRatingQuery>;
export const getUserRatingStats = /* GraphQL */ `query GetUserRatingStats($input: GetUserRatingStatsInput!) {
  getUserRatingStats(input: $input) {
    count
    rating
  }
}
` as GeneratedQuery<APITypes.GetUserRatingStatsQueryVariables, APITypes.GetUserRatingStatsQuery>;
export const getNoteRatingStats = /* GraphQL */ `query GetNoteRatingStats($input: GetNoteRatingStatsInput!) {
  getNoteRatingStats(input: $input) {
    count
    rating
  }
}
` as GeneratedQuery<APITypes.GetNoteRatingStatsQueryVariables, APITypes.GetNoteRatingStatsQuery>;
export const getFlashcardSetViewer = /* GraphQL */ `query GetFlashcardSetViewer($input: GetFlashcardSetViewerInput!) {
  getFlashcardSetViewer(input: $input) {
    userId
    flashcardSetId
    position
    starred
    length
    studyFrom
    studiedFlashcards
    order
    shuffled
    lastViewedFlashcard
    answerSide
    reviewMode
  }
}
` as GeneratedQuery<APITypes.GetFlashcardSetViewerQueryVariables, APITypes.GetFlashcardSetViewerQuery>;
export const getQuizletFlashcards = /* GraphQL */ `query GetQuizletFlashcards($input: QuizletParseInput!) {
  getQuizletFlashcards(input: $input) {
    data
    title
  }
}
` as GeneratedQuery<APITypes.GetQuizletFlashcardsQueryVariables, APITypes.GetQuizletFlashcardsQuery>;
export const requestAICompletion = /* GraphQL */ `query RequestAICompletion($input: AICompletionInput!) {
  requestAICompletion(input: $input) {
    userId
    timestamp
    noteId
    flashcardSetId
    flashcardId
    itemId
    itemType
    id
    input
    messages {
      role
      content
    }
    output
    inputTokens
    outputTokens
    subject
    topic
    type
    timeTaken
    params
  }
}
` as GeneratedQuery<APITypes.RequestAICompletionQueryVariables, APITypes.RequestAICompletionQuery>;
export const requestAICompletionV2 = /* GraphQL */ `query RequestAICompletionV2($input: AICompletionInput!) {
  requestAICompletionV2(input: $input) {
    item {
      userId
      timestamp
      noteId
      flashcardSetId
      flashcardId
      itemId
      itemType
      id
      input
      messages {
        role
        content
      }
      output
      inputTokens
      outputTokens
      subject
      topic
      type
      timeTaken
      params
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedQuery<APITypes.RequestAICompletionV2QueryVariables, APITypes.RequestAICompletionV2Query>;
export const getExam = /* GraphQL */ `query GetExam($input: GetExamInput!) {
  getExam(input: $input) {
    type
    name
    description
    unitLabel
    sectionLabel
    baseLink
    image
    category
    color
    date
    time
    index
    noteId
    flashcardSetId
    discord {
      name
      link
      image
    }
    youtube {
      name
      link
      image
    }
    units {
      name
      image
      noteId
      flashcardSetId
      sections
    }
    crams {
      name
      image
      noteId
      flashcardSetId
      sections
    }
  }
}
` as GeneratedQuery<APITypes.GetExamQueryVariables, APITypes.GetExamQuery>;
export const getExamMCQ = /* GraphQL */ `query GetExamMCQ($input: GetExamMCQInput!) {
  getExamMCQ(input: $input) {
    exam
    questionId
    difficulty
    choices
    question
    reasons
    rating
    ratingCount
    examUnit
    examSection
    noteId
    flashcardSetId
    subject
    topic
    created
    updated
    url
    prompt
    numCorrect
    numQuestions
    stimuli
    stimulusGroupId
    questionGroupId
    replaced
    calc
  }
}
` as GeneratedQuery<APITypes.GetExamMCQQueryVariables, APITypes.GetExamMCQQuery>;
export const getExamFRQ = /* GraphQL */ `query GetExamFRQ($input: GetExamFRQInput!) {
  getExamFRQ(input: $input) {
    exam
    questionId
    difficulty
    question
    rating
    ratingCount
    examUnit
    examSection
    noteId
    flashcardSetId
    subject
    topic
    created
    updated
    url
    prompt
    stimuli {
      questionId
      stimulusId
      content
      stimulusType
      schema
    }
    name
    parts {
      question
      rating
      ratingCount
      stimuli {
        questionId
        stimulusId
        content
        stimulusType
        schema
      }
      prefill
    }
    rubric {
      items {
        guidelines
        points
      }
    }
    starred
    calc
    frqType
  }
}
` as GeneratedQuery<APITypes.GetExamFRQQueryVariables, APITypes.GetExamFRQQuery>;
export const getSubject = /* GraphQL */ `query GetSubject($input: GetSubjectInput!) {
  getSubject(input: $input) {
    subject
    picture
    color
    category
    order
    topics
  }
}
` as GeneratedQuery<APITypes.GetSubjectQueryVariables, APITypes.GetSubjectQuery>;
export const getBlog = /* GraphQL */ `query GetBlog($input: GetBlogInput!) {
  getBlog(input: $input) {
    userId
    blogId
    title
    slug
    content
    summary
    created
    updated
    tags
    coverImage {
      url
      width
      height
    }
    metadata {
      title
      description
    }
    draft
    trash
    type
    pinned
  }
}
` as GeneratedQuery<APITypes.GetBlogQueryVariables, APITypes.GetBlogQuery>;
export const getBlogById = /* GraphQL */ `query GetBlogById($input: GetBlogByIdInput!) {
  getBlogById(input: $input) {
    userId
    blogId
    title
    slug
    content
    summary
    created
    updated
    tags
    coverImage {
      url
      width
      height
    }
    metadata {
      title
      description
    }
    draft
    trash
    type
    pinned
  }
}
` as GeneratedQuery<APITypes.GetBlogByIdQueryVariables, APITypes.GetBlogByIdQuery>;
export const getRecommendedBlogs = /* GraphQL */ `query GetRecommendedBlogs($input: GetRecommendedBlogInput!) {
  getRecommendedBlogs(input: $input) {
    items {
      userId
      blogId
      title
      slug
      content
      summary
      created
      updated
      tags
      coverImage {
        url
        width
        height
      }
      metadata {
        title
        description
      }
      draft
      trash
      type
      pinned
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.GetRecommendedBlogsQueryVariables, APITypes.GetRecommendedBlogsQuery>;
export const listBlogs = /* GraphQL */ `query ListBlogs($input: ListBlogsInput!) {
  listBlogs(input: $input) {
    items {
      userId
      blogId
      title
      slug
      content
      summary
      created
      updated
      tags
      coverImage {
        url
        width
        height
      }
      metadata {
        title
        description
      }
      draft
      trash
      type
      pinned
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListBlogsQueryVariables, APITypes.ListBlogsQuery>;
export const listBlogsByUser = /* GraphQL */ `query ListBlogsByUser($input: ListBlogsByUserInput!) {
  listBlogsByUser(input: $input) {
    items {
      userId
      blogId
      title
      slug
      content
      summary
      created
      updated
      tags
      coverImage {
        url
        width
        height
      }
      metadata {
        title
        description
      }
      draft
      trash
      type
      pinned
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListBlogsByUserQueryVariables, APITypes.ListBlogsByUserQuery>;
export const getStudySession = /* GraphQL */ `query GetStudySession($input: GetStudySessionInput!) {
  getStudySession(input: $input) {
    userId
    itemId
    type
    created
    updated
    examDate
    unmigrated
    newCards
    nextDue
    nextNewDue
    settings {
      LEARN {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        reType
        length
        override
      }
      TEST {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        length
      }
      SPACED {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        cardsPerSession
        intervals
        override
      }
      MATCH {
        grid
        starred
        length
      }
      REVIEW {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        reType
        sorting
      }
    }
    progress {
      NEW
      LEARNING
      REVIEWING
      MASTERED
    }
    practiceTests {
      timeTaken
      timestamp
      score
    }
    syncTime
    lastView
    title
  }
}
` as GeneratedQuery<APITypes.GetStudySessionQueryVariables, APITypes.GetStudySessionQuery>;
export const listStudySessions = /* GraphQL */ `query ListStudySessions($input: ListStudySessionsInput!) {
  listStudySessions(input: $input) {
    items {
      userId
      itemId
      type
      created
      updated
      examDate
      unmigrated
      newCards
      nextDue
      nextNewDue
      settings {
        LEARN {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
          override
        }
        MATCH {
          grid
          starred
          length
        }
        REVIEW {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          sorting
        }
      }
      progress {
        NEW
        LEARNING
        REVIEWING
        MASTERED
      }
      practiceTests {
        timeTaken
        timestamp
        score
      }
      syncTime
      lastView
      title
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListStudySessionsQueryVariables, APITypes.ListStudySessionsQuery>;
export const listRecentStudySessions =
    /* GraphQL */ `query ListRecentStudySessions($input: ListRecentStudySessionsInput!) {
  listRecentStudySessions(input: $input) {
    items {
      userId
      itemId
      type
      created
      updated
      examDate
      unmigrated
      newCards
      nextDue
      nextNewDue
      settings {
        LEARN {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
          override
        }
        MATCH {
          grid
          starred
          length
        }
        REVIEW {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          sorting
        }
      }
      progress {
        NEW
        LEARNING
        REVIEWING
        MASTERED
      }
      practiceTests {
        timeTaken
        timestamp
        score
      }
      syncTime
      lastView
      title
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListRecentStudySessionsQueryVariables, APITypes.ListRecentStudySessionsQuery>;
export const listFlashcardStudyStates =
    /* GraphQL */ `query ListFlashcardStudyStates($input: ListFlashcardStudyStatesInput!) {
  listFlashcardStudyStates(input: $input) {
    items {
      userId
      flashcardId
      flashcardSetId
      created
      updated
      distractors
      mastery
      lastStudied
      blank
      sort
      nextDue
      difficulty
      interval
      eFactor
      progress
      history {
        timestamp
        timeTaken
        mode
        questionType
        selectedFlashcardId
        side
        button
        isCorrect
        answer
      }
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFlashcardStudyStatesQueryVariables, APITypes.ListFlashcardStudyStatesQuery>;
export const listItemAIChats = /* GraphQL */ `query ListItemAIChats($input: ListItemAIChatsInput!) {
  listItemAIChats(input: $input) {
    items {
      userId
      timestamp
      noteId
      flashcardSetId
      flashcardId
      itemId
      itemType
      id
      input
      messages {
        role
        content
      }
      output
      inputTokens
      outputTokens
      subject
      topic
      type
      timeTaken
      params
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListItemAIChatsQueryVariables, APITypes.ListItemAIChatsQuery>;
export const getClass = /* GraphQL */ `query GetClass($input: GetClassInput!) {
  getClass(input: $input) {
    __typename
    userId
    classId
    sections {
      name
      id
      color
      timings {
        start
        end
        days
      }
    }
    members {
      userId
      role
      sections
      pending
      Name
      pictureUrl
      username
      dpa
      lastLogIn
    }
    memberCount
    pinned
    created
    updated
    name
    description
    timings {
      start
      end
      days
    }
    timezone
    org
    schoolId
    grade
    subject
    topic
    exam_v2
    examUnit
    examSection
    icon {
      emoji
      skinTone
    }
    color
    cover
    googleClassId
    fileCount
    partial
    flashcardStudySettings {
      blockedStudyModes
      settings {
        LEARN {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          locked
          grid
          starred
          length
        }
        REVIEW {
          locked
          questionTypes
          answerSide
          starred
          position
          shuffled
          fuzzy
          reType
        }
      }
    }
  }
}
` as GeneratedQuery<APITypes.GetClassQueryVariables, APITypes.GetClassQuery>;
export const listClasses = /* GraphQL */ `query ListClasses($input: ListClassesInput!) {
  listClasses(input: $input) {
    items {
      __typename
      userId
      classId
      sections {
        name
        id
        color
        timings {
          start
          end
          days
        }
      }
      members {
        userId
        role
        sections
        pending
        Name
        pictureUrl
        username
        dpa
        lastLogIn
      }
      memberCount
      pinned
      created
      updated
      name
      description
      timings {
        start
        end
        days
      }
      timezone
      org
      schoolId
      grade
      subject
      topic
      exam_v2
      examUnit
      examSection
      icon {
        emoji
        skinTone
      }
      color
      cover
      googleClassId
      fileCount
      partial
      flashcardStudySettings {
        blockedStudyModes
        settings {
          LEARN {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            reType
            length
            override
          }
          TEST {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            length
          }
          SPACED {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            cardsPerSession
            intervals
          }
          MATCH {
            locked
            grid
            starred
            length
          }
          REVIEW {
            locked
            questionTypes
            answerSide
            starred
            position
            shuffled
            fuzzy
            reType
          }
        }
      }
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListClassesQueryVariables, APITypes.ListClassesQuery>;
export const listClassStudySessions =
    /* GraphQL */ `query ListClassStudySessions($input: ListClassStudySessionsInput!) {
  listClassStudySessions(input: $input) {
    items {
      userId
      itemId
      type
      created
      updated
      examDate
      unmigrated
      newCards
      nextDue
      nextNewDue
      settings {
        LEARN {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
          override
        }
        MATCH {
          grid
          starred
          length
        }
        REVIEW {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          sorting
        }
      }
      progress {
        NEW
        LEARNING
        REVIEWING
        MASTERED
      }
      practiceTests {
        timeTaken
        timestamp
        score
      }
      syncTime
      lastView
      title
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListClassStudySessionsQueryVariables, APITypes.ListClassStudySessionsQuery>;
export const listClassFlashcardStudyStates = /* GraphQL */ `query ListClassFlashcardStudyStates(
  $input: ListClassFlashcardStudyStatesInput!
) {
  listClassFlashcardStudyStates(input: $input) {
    items {
      userId
      flashcardId
      flashcardSetId
      created
      updated
      distractors
      mastery
      lastStudied
      blank
      sort
      nextDue
      difficulty
      interval
      eFactor
      progress
      history {
        timestamp
        timeTaken
        mode
        questionType
        selectedFlashcardId
        side
        button
        isCorrect
        answer
      }
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListClassFlashcardStudyStatesQueryVariables, APITypes.ListClassFlashcardStudyStatesQuery>;
export const listNotesByClass = /* GraphQL */ `query ListNotesByClass($input: ListNotesByClassInput!) {
  listNotesByClass(input: $input) {
    items {
      __typename
      userId
      noteId
      content
      hash
      public
      draft
      password
      summary
      title
      trash
      created
      updated
      noteCode
      file
      folderId
      flashcardSetId
      mediaId
      importType
      subject
      views
      verified
      classPublic
      schoolId
      grade
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      classId
      addedAt
      sections
      lock
      partial
      xpTracked
      isAI
      aiTool
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      neverPublic
      deadline
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListNotesByClassQueryVariables, APITypes.ListNotesByClassQuery>;
export const listFlashcardSetsByClass =
    /* GraphQL */ `query ListFlashcardSetsByClass($input: ListFlashcardSetsByClassInput!) {
  listFlashcardSetsByClass(input: $input) {
    items {
      __typename
      userId
      flashcardSetId
      sort
      noteId
      mediaId
      position
      title
      description
      public
      password
      trash
      draft
      starred
      size
      studyFrom
      created
      updated
      quizletUrl
      answerSide
      shuffled
      flashcards {
        flashcardId
        flashcardSetId
        userId
        term
        termAudio
        definition
        definitionAudio
        distractorIds
        questionType
        isCorrect
        draft
        trash
        disabled
        image
        secondaryImage
        edited
        created
        updated
        twoSided
        schedule
        quality
        termRecordedAudio
        definitionRecordedAudio
        mcOptions
        termCustomHint
        definitionCustomHint
      }
      termLanguage
      definitionLanguage
      relatedNotes
      order
      flashcardSetCode
      views
      classPublic
      subject
      verified
      schoolId
      grade
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      folderId
      rating
      ratingCount
      classId
      addedAt
      sections
      lock
      partial
      xpTracked
      studySettings {
        blockedStudyModes
        settings {
          LEARN {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            reType
            length
            override
          }
          TEST {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            length
          }
          SPACED {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            cardsPerSession
            intervals
          }
          MATCH {
            locked
            grid
            starred
            length
          }
          REVIEW {
            locked
            questionTypes
            answerSide
            starred
            position
            shuffled
            fuzzy
            reType
          }
        }
      }
      isAI
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      deadline
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFlashcardSetsByClassQueryVariables, APITypes.ListFlashcardSetsByClassQuery>;
export const listMediasByClass = /* GraphQL */ `query ListMediasByClass($input: ListMediasByClassInput!) {
  listMediasByClass(input: $input) {
    items {
      __typename
      userId
      mediaId
      created
      updated
      title
      description
      password
      public
      noteId
      flashcardSetId
      folderId
      trash
      type
      fileType
      pages
      length
      bucket
      embedUrl
      embedCommands
      subject
      topic
      exam_v2
      examUnit
      examSection
      tags
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      verified
      transcript
      schoolId
      grade
      views
      jwt
      classId
      addedAt
      sections
      partial
      xpTracked
      isAI
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListMediasByClassQueryVariables, APITypes.ListMediasByClassQuery>;
export const listFoldersByClass = /* GraphQL */ `query ListFoldersByClass($input: ListFoldersByClassInput!) {
  listFoldersByClass(input: $input) {
    items {
      __typename
      userId
      folderId
      parentId
      name
      color
      description
      created
      updated
      notesCount
      trash
      public
      password
      icon {
        emoji
        skinTone
      }
      classId
      addedAt
      sections
      partial
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListFoldersByClassQueryVariables, APITypes.ListFoldersByClassQuery>;
export const listAllAccessibleFolders = /* GraphQL */ `query ListAllAccessibleFolders {
  listAllAccessibleFolders {
    items {
      __typename
      userId
      folderId
      parentId
      name
      color
      description
      created
      updated
      notesCount
      trash
      public
      password
      icon {
        emoji
        skinTone
      }
      classId
      addedAt
      sections
      partial
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListAllAccessibleFoldersQueryVariables, APITypes.ListAllAccessibleFoldersQuery>;
export const adminListOrgMembers = /* GraphQL */ `query AdminListOrgMembers($input: AdminListOrgInput!) {
  adminListOrgMembers(input: $input) {
    items {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      Name
      pictureUrl
      username
      birthday
      created
      lastLogIn
      ref_v2
      numFollowers
      numFollowing
      schoolId
      grade
      subscriptionType
      isTrialing
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      orgRequests
      orgReqTime
      classCount
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.AdminListOrgMembersQueryVariables, APITypes.AdminListOrgMembersQuery>;
export const adminListOrgClasses = /* GraphQL */ `query AdminListOrgClasses($input: AdminListOrgInput!) {
  adminListOrgClasses(input: $input) {
    items {
      __typename
      userId
      classId
      sections {
        name
        id
        color
        timings {
          start
          end
          days
        }
      }
      members {
        userId
        role
        sections
        pending
        Name
        pictureUrl
        username
        dpa
        lastLogIn
      }
      memberCount
      pinned
      created
      updated
      name
      description
      timings {
        start
        end
        days
      }
      timezone
      org
      schoolId
      grade
      subject
      topic
      exam_v2
      examUnit
      examSection
      icon {
        emoji
        skinTone
      }
      color
      cover
      googleClassId
      fileCount
      partial
      flashcardStudySettings {
        blockedStudyModes
        settings {
          LEARN {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            reType
            length
            override
          }
          TEST {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            length
          }
          SPACED {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            cardsPerSession
            intervals
          }
          MATCH {
            locked
            grid
            starred
            length
          }
          REVIEW {
            locked
            questionTypes
            answerSide
            starred
            position
            shuffled
            fuzzy
            reType
          }
        }
      }
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.AdminListOrgClassesQueryVariables, APITypes.AdminListOrgClassesQuery>;
export const getOrganization = /* GraphQL */ `query GetOrganization($input: GetOrganizationInput!) {
  getOrganization(input: $input) {
    org
    schoolId
    created
    updated
    name
    pictureUrl
    country
    state
    files {
      url
      name
    }
    students
    teachers
    syncTimestamp
    isDirty
    paywall {
      count
      lastSent
    }
    aliases
    stage
    referrals {
      userId
      created
      sent
      type
      email
    }
    dpa {
      name
      email
      link
      type
    }
    admins {
      userId
      schoolId
    }
    pendingAdmins {
      Email
      schoolId
    }
    settings {
      schoolId
      sharing
      subsAllowed
      ai
    }
    schoolsSettings {
      schoolId
      sharing
      subsAllowed
      ai
    }
    verifiedSchools
    subscriptions {
      schoolId
      created
      current_period_end
      studentPlan
      teacherPlan
      studentsPaidFor
      teachersPaidFor
      paidStudents
      paidTeachers
      autoSubsStudent
      autoSubsTeacher
    }
    customerId
    provider
    elDistrictId
    elIntegrationId
  }
}
` as GeneratedQuery<APITypes.GetOrganizationQueryVariables, APITypes.GetOrganizationQuery>;
export const listAllOrganizations = /* GraphQL */ `query ListAllOrganizations {
  listAllOrganizations {
    items {
      org
      schoolId
      created
      updated
      name
      pictureUrl
      country
      state
      files {
        url
        name
      }
      students
      teachers
      syncTimestamp
      isDirty
      paywall {
        count
        lastSent
      }
      aliases
      stage
      referrals {
        userId
        created
        sent
        type
        email
      }
      dpa {
        name
        email
        link
        type
      }
      admins {
        userId
        schoolId
      }
      pendingAdmins {
        Email
        schoolId
      }
      settings {
        schoolId
        sharing
        subsAllowed
        ai
      }
      schoolsSettings {
        schoolId
        sharing
        subsAllowed
        ai
      }
      verifiedSchools
      subscriptions {
        schoolId
        created
        current_period_end
        studentPlan
        teacherPlan
        studentsPaidFor
        teachersPaidFor
        paidStudents
        paidTeachers
        autoSubsStudent
        autoSubsTeacher
      }
      customerId
      provider
      elDistrictId
      elIntegrationId
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListAllOrganizationsQueryVariables, APITypes.ListAllOrganizationsQuery>;
export const listStoreProductMetadata =
    /* GraphQL */ `query ListStoreProductMetadata($input: ListStoreProductMetadataInput) {
  listStoreProductMetadata(input: $input) {
    items {
      category
      storeProductId
      name
      description
      link
      feature
      price
      discount
      max
      total
      group
      color
      planType
    }
  }
}
` as GeneratedQuery<APITypes.ListStoreProductMetadataQueryVariables, APITypes.ListStoreProductMetadataQuery>;
export const listStoreProductHistory =
    /* GraphQL */ `query ListStoreProductHistory($input: ListStoreProductHistoryInput) {
  listStoreProductHistory(input: $input) {
    items {
      userId
      storeProductId
      current
      bought
      price
      code
      discount
      expiryTime
    }
  }
}
` as GeneratedQuery<APITypes.ListStoreProductHistoryQueryVariables, APITypes.ListStoreProductHistoryQuery>;
export const listBadgeProgress = /* GraphQL */ `query ListBadgeProgress($input: ListBadgeProgressInput!) {
  listBadgeProgress(input: $input) {
    items {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
  }
}
` as GeneratedQuery<APITypes.ListBadgeProgressQueryVariables, APITypes.ListBadgeProgressQuery>;
export const listDailyActivity = /* GraphQL */ `query ListDailyActivity($input: ListDailyActivityInput!) {
  listDailyActivity(input: $input) {
    items {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListDailyActivityQueryVariables, APITypes.ListDailyActivityQuery>;
export const listBadgesMetadata = /* GraphQL */ `query ListBadgesMetadata($input: ListBadgesMetadataInput) {
  listBadgesMetadata(input: $input) {
    items {
      group
      badge
      name
      description
      success
      failure
      tiers {
        xpReward
        coinReward
        required
      }
    }
  }
}
` as GeneratedQuery<APITypes.ListBadgesMetadataQueryVariables, APITypes.ListBadgesMetadataQuery>;
export const getUserByRefV2 = /* GraphQL */ `query GetUserByRefV2($input: GetUserByRefV2Input!) {
  getUserByRefV2(input: $input) {
    Email
    org
    accountType
    ID
    elID
    clID
    isSSOChecked
    Name
    pictureUrl
    username
    birthday
    created
    lastLogIn
    ref_v2
    numFollowers
    numFollowing
    schoolId
    grade
    subscriptionType
    isTrialing
    signInTypes
    orgPlanType
    orgPlanCreated
    lastOrgPlanUpdated
    orgRequests
    orgReqTime
    classCount
  }
}
` as GeneratedQuery<APITypes.GetUserByRefV2QueryVariables, APITypes.GetUserByRefV2Query>;
export const getIntercomUserHash = /* GraphQL */ `query GetIntercomUserHash($input: getIntercomUserHashInput) {
  getIntercomUserHash(input: $input)
}
` as GeneratedQuery<APITypes.GetIntercomUserHashQueryVariables, APITypes.GetIntercomUserHashQuery>;
export const getAssignment = /* GraphQL */ `query GetAssignment($input: GetAssignmentInput!) {
  getAssignment(input: $input) {
    item {
      __typename
      userId
      assignmentId
      classId
      addedAt
      config
      files {
        itemId
        itemType
      }
      sections
      partial
      status
      deadline
      type
      settings {
        timeLimit
        standards
        grade
        maxSubmissions
        language
        inputMethods
        outputMethods
        autoGrade
        rubric {
          A {
            score
            condition
          }
          B {
            score
            condition
          }
          C {
            score
            condition
          }
          D {
            score
            condition
          }
          F {
            score
            condition
          }
        }
        helpfulness
        length
        priorKnowledge
        rules
        suggestions
        initialPrompt
      }
      created
      updated
      feedback {
        strength
        improvement
        followUp
        comments
      }
      title
      description
      instructions
      image
      icon {
        emoji
        skinTone
      }
      public
      password
      trash
      folderId
      subject
      topic
      numCompleted
      views
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedQuery<APITypes.GetAssignmentQueryVariables, APITypes.GetAssignmentQuery>;
export const getAssignmentSession = /* GraphQL */ `query GetAssignmentSession($input: GetAssignmentSessionInput!) {
  getAssignmentSession(input: $input) {
    session {
      assignmentId
      sessionId
      userId
      status
      created
      updated
      submitted
      duration
      score
      rubric
      feedback {
        strength
        improvement
        followUp
        comments
      }
    }
    messages {
      sessionId
      messageId
      created
      content
      contentFlag
      sender
      attachments {
        url
        parsed
        type
      }
    }
  }
}
` as GeneratedQuery<APITypes.GetAssignmentSessionQueryVariables, APITypes.GetAssignmentSessionQuery>;
export const listAssignments = /* GraphQL */ `query ListAssignments($input: ListAssignmentsInput!) {
  listAssignments(input: $input) {
    items {
      __typename
      userId
      assignmentId
      classId
      addedAt
      config
      files {
        itemId
        itemType
      }
      sections
      partial
      status
      deadline
      type
      settings {
        timeLimit
        standards
        grade
        maxSubmissions
        language
        inputMethods
        outputMethods
        autoGrade
        rubric {
          A {
            score
            condition
          }
          B {
            score
            condition
          }
          C {
            score
            condition
          }
          D {
            score
            condition
          }
          F {
            score
            condition
          }
        }
        helpfulness
        length
        priorKnowledge
        rules
        suggestions
        initialPrompt
      }
      created
      updated
      feedback {
        strength
        improvement
        followUp
        comments
      }
      title
      description
      instructions
      image
      icon {
        emoji
        skinTone
      }
      public
      password
      trash
      folderId
      subject
      topic
      numCompleted
      views
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListAssignmentsQueryVariables, APITypes.ListAssignmentsQuery>;
export const listAssignmentsByFolder =
    /* GraphQL */ `query ListAssignmentsByFolder($input: ListAssignmentsByFolderInput!) {
  listAssignmentsByFolder(input: $input) {
    items {
      __typename
      userId
      assignmentId
      classId
      addedAt
      config
      files {
        itemId
        itemType
      }
      sections
      partial
      status
      deadline
      type
      settings {
        timeLimit
        standards
        grade
        maxSubmissions
        language
        inputMethods
        outputMethods
        autoGrade
        rubric {
          A {
            score
            condition
          }
          B {
            score
            condition
          }
          C {
            score
            condition
          }
          D {
            score
            condition
          }
          F {
            score
            condition
          }
        }
        helpfulness
        length
        priorKnowledge
        rules
        suggestions
        initialPrompt
      }
      created
      updated
      feedback {
        strength
        improvement
        followUp
        comments
      }
      title
      description
      instructions
      image
      icon {
        emoji
        skinTone
      }
      public
      password
      trash
      folderId
      subject
      topic
      numCompleted
      views
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListAssignmentsByFolderQueryVariables, APITypes.ListAssignmentsByFolderQuery>;
export const listAssignmentsByClass =
    /* GraphQL */ `query ListAssignmentsByClass($input: ListAssignmentsByClassInput!) {
  listAssignmentsByClass(input: $input) {
    items {
      __typename
      userId
      assignmentId
      classId
      addedAt
      config
      files {
        itemId
        itemType
      }
      sections
      partial
      status
      deadline
      type
      settings {
        timeLimit
        standards
        grade
        maxSubmissions
        language
        inputMethods
        outputMethods
        autoGrade
        rubric {
          A {
            score
            condition
          }
          B {
            score
            condition
          }
          C {
            score
            condition
          }
          D {
            score
            condition
          }
          F {
            score
            condition
          }
        }
        helpfulness
        length
        priorKnowledge
        rules
        suggestions
        initialPrompt
      }
      created
      updated
      feedback {
        strength
        improvement
        followUp
        comments
      }
      title
      description
      instructions
      image
      icon {
        emoji
        skinTone
      }
      public
      password
      trash
      folderId
      subject
      topic
      numCompleted
      views
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListAssignmentsByClassQueryVariables, APITypes.ListAssignmentsByClassQuery>;
export const listAssignmentSessions =
    /* GraphQL */ `query ListAssignmentSessions($input: ListAssignmentSessionsInput!) {
  listAssignmentSessions(input: $input) {
    items {
      assignmentId
      sessionId
      userId
      status
      created
      updated
      submitted
      duration
      score
      rubric
      feedback {
        strength
        improvement
        followUp
        comments
      }
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListAssignmentSessionsQueryVariables, APITypes.ListAssignmentSessionsQuery>;
export const listAssignmentSessionsByUser =
    /* GraphQL */ `query ListAssignmentSessionsByUser($input: ListAssignmentSessionsByUserInput!) {
  listAssignmentSessionsByUser(input: $input) {
    items {
      assignmentId
      sessionId
      userId
      status
      created
      updated
      submitted
      duration
      score
      rubric
      feedback {
        strength
        improvement
        followUp
        comments
      }
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListAssignmentSessionsByUserQueryVariables, APITypes.ListAssignmentSessionsByUserQuery>;
export const listAssignmentMessages =
    /* GraphQL */ `query ListAssignmentMessages($input: ListAssignmentMessagesInput!) {
  listAssignmentMessages(input: $input) {
    items {
      sessionId
      messageId
      created
      content
      contentFlag
      sender
      attachments {
        url
        parsed
        type
      }
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListAssignmentMessagesQueryVariables, APITypes.ListAssignmentMessagesQuery>;
export const listScanHistory = /* GraphQL */ `query ListScanHistory($input: ListScanHistoryInput) {
  listScanHistory(input: $input) {
    items {
      userId
      scanId
      created
      updated
      subject
      topic
      context
      imageDescription
      relatedTopics
      questions {
        text
        type
        choices
        answer {
          text
          steps
        }
      }
      pinned
      summary
      videoResult
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListScanHistoryQueryVariables, APITypes.ListScanHistoryQuery>;
export const getScanHistory = /* GraphQL */ `query GetScanHistory($input: GetScanHistoryInput!) {
  getScanHistory(input: $input) {
    userId
    scanId
    created
    updated
    subject
    topic
    context
    imageDescription
    relatedTopics
    questions {
      text
      type
      choices
      answer {
        text
        steps
      }
    }
    pinned
    summary
    videoResult
  }
}
` as GeneratedQuery<APITypes.GetScanHistoryQueryVariables, APITypes.GetScanHistoryQuery>;
export const listExamHistory = /* GraphQL */ `query ListExamHistory($input: ListExamHistoryInput) {
  listExamHistory(input: $input) {
    items {
      userId
      historyId
      exam
      type
      sections {
        name
        time
        breakdown {
          name
          type
          mcqResult {
            items {
              questionId
              selectedAnswer
              isCorrect
            }
          }
          frqResult {
            items {
              questionId
              partAnswers {
                answer
                earnedScore
                possibleScore
                feedback
                attachments {
                  url
                  parsed
                  type
                }
              }
            }
          }
        }
      }
      mcqMetrics
      frqMetrics
      score
      created
      updated
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListExamHistoryQueryVariables, APITypes.ListExamHistoryQuery>;
export const listExamHistoryByExam = /* GraphQL */ `query ListExamHistoryByExam($input: ListExamHistoryByExamInput!) {
  listExamHistoryByExam(input: $input) {
    items {
      userId
      historyId
      exam
      type
      sections {
        name
        time
        breakdown {
          name
          type
          mcqResult {
            items {
              questionId
              selectedAnswer
              isCorrect
            }
          }
          frqResult {
            items {
              questionId
              partAnswers {
                answer
                earnedScore
                possibleScore
                feedback
                attachments {
                  url
                  parsed
                  type
                }
              }
            }
          }
        }
      }
      mcqMetrics
      frqMetrics
      score
      created
      updated
    }
    nextToken
  }
}
` as GeneratedQuery<APITypes.ListExamHistoryByExamQueryVariables, APITypes.ListExamHistoryByExamQuery>;
export const getExamHistory = /* GraphQL */ `query GetExamHistory($input: GetExamHistoryInput!) {
  getExamHistory(input: $input) {
    userId
    historyId
    exam
    type
    sections {
      name
      time
      breakdown {
        name
        type
        mcqResult {
          items {
            questionId
            selectedAnswer
            isCorrect
          }
        }
        frqResult {
          items {
            questionId
            partAnswers {
              answer
              earnedScore
              possibleScore
              feedback
              attachments {
                url
                parsed
                type
              }
            }
          }
        }
      }
    }
    mcqMetrics
    frqMetrics
    score
    created
    updated
  }
}
` as GeneratedQuery<APITypes.GetExamHistoryQueryVariables, APITypes.GetExamHistoryQuery>;
