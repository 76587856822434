/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "./schema";
type GeneratedMutation<InputType, OutputType> = string & {
    __generatedMutationInput: InputType;
    __generatedMutationOutput: OutputType;
};

export const createNote = /* GraphQL */ `mutation CreateNote($input: CreateNoteInput!) {
  createNote(input: $input) {
    __typename
    userId
    noteId
    content
    hash
    public
    draft
    password
    summary
    title
    trash
    created
    updated
    noteCode
    file
    folderId
    flashcardSetId
    mediaId
    importType
    subject
    views
    verified
    classPublic
    schoolId
    grade
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    classId
    addedAt
    sections
    lock
    partial
    xpTracked
    isAI
    aiTool
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    neverPublic
    deadline
  }
}
` as GeneratedMutation<APITypes.CreateNoteMutationVariables, APITypes.CreateNoteMutation>;
export const createNoteV2 = /* GraphQL */ `mutation CreateNoteV2($input: CreateNoteInput!) {
  createNoteV2(input: $input) {
    item {
      __typename
      userId
      noteId
      content
      hash
      public
      draft
      password
      summary
      title
      trash
      created
      updated
      noteCode
      file
      folderId
      flashcardSetId
      mediaId
      importType
      subject
      views
      verified
      classPublic
      schoolId
      grade
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      classId
      addedAt
      sections
      lock
      partial
      xpTracked
      isAI
      aiTool
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      neverPublic
      deadline
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedMutation<APITypes.CreateNoteV2MutationVariables, APITypes.CreateNoteV2Mutation>;
export const deleteNote = /* GraphQL */ `mutation DeleteNote($input: DeleteNoteInput!) {
  deleteNote(input: $input) {
    __typename
    userId
    noteId
    content
    hash
    public
    draft
    password
    summary
    title
    trash
    created
    updated
    noteCode
    file
    folderId
    flashcardSetId
    mediaId
    importType
    subject
    views
    verified
    classPublic
    schoolId
    grade
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    classId
    addedAt
    sections
    lock
    partial
    xpTracked
    isAI
    aiTool
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    neverPublic
    deadline
  }
}
` as GeneratedMutation<APITypes.DeleteNoteMutationVariables, APITypes.DeleteNoteMutation>;
export const updateNote = /* GraphQL */ `mutation UpdateNote($input: UpdateNoteInput!) {
  updateNote(input: $input) {
    __typename
    userId
    noteId
    content
    hash
    public
    draft
    password
    summary
    title
    trash
    created
    updated
    noteCode
    file
    folderId
    flashcardSetId
    mediaId
    importType
    subject
    views
    verified
    classPublic
    schoolId
    grade
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    classId
    addedAt
    sections
    lock
    partial
    xpTracked
    isAI
    aiTool
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    neverPublic
    deadline
  }
}
` as GeneratedMutation<APITypes.UpdateNoteMutationVariables, APITypes.UpdateNoteMutation>;
export const updateNoteV2 = /* GraphQL */ `mutation UpdateNoteV2($input: UpdateNoteInput!) {
  updateNoteV2(input: $input) {
    item {
      __typename
      userId
      noteId
      content
      hash
      public
      draft
      password
      summary
      title
      trash
      created
      updated
      noteCode
      file
      folderId
      flashcardSetId
      mediaId
      importType
      subject
      views
      verified
      classPublic
      schoolId
      grade
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      rating
      ratingCount
      classId
      addedAt
      sections
      lock
      partial
      xpTracked
      isAI
      aiTool
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      neverPublic
      deadline
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedMutation<APITypes.UpdateNoteV2MutationVariables, APITypes.UpdateNoteV2Mutation>;
export const generateMediaTranscription =
    /* GraphQL */ `mutation GenerateMediaTranscription($input: GenerateMediaTranscriptionInput!) {
  generateMediaTranscription(input: $input) {
    __typename
    userId
    mediaId
    created
    updated
    title
    description
    password
    public
    noteId
    flashcardSetId
    folderId
    trash
    type
    fileType
    pages
    length
    bucket
    embedUrl
    embedCommands
    subject
    topic
    exam_v2
    examUnit
    examSection
    tags
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    verified
    transcript
    schoolId
    grade
    views
    jwt
    classId
    addedAt
    sections
    partial
    xpTracked
    isAI
  }
}
` as GeneratedMutation<
        APITypes.GenerateMediaTranscriptionMutationVariables,
        APITypes.GenerateMediaTranscriptionMutation
    >;
export const createMediaWithTranscription = /* GraphQL */ `mutation CreateMediaWithTranscription(
  $input: CreateMediaWithTranscriptionInput!
) {
  createMediaWithTranscription(input: $input) {
    __typename
    userId
    mediaId
    created
    updated
    title
    description
    password
    public
    noteId
    flashcardSetId
    folderId
    trash
    type
    fileType
    pages
    length
    bucket
    embedUrl
    embedCommands
    subject
    topic
    exam_v2
    examUnit
    examSection
    tags
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    verified
    transcript
    schoolId
    grade
    views
    jwt
    classId
    addedAt
    sections
    partial
    xpTracked
    isAI
  }
}
` as GeneratedMutation<
    APITypes.CreateMediaWithTranscriptionMutationVariables,
    APITypes.CreateMediaWithTranscriptionMutation
>;
export const createMediaFromYoutube =
    /* GraphQL */ `mutation CreateMediaFromYoutube($input: CreateMediaFromYoutubeInput!) {
  createMediaFromYoutube(input: $input)
}
` as GeneratedMutation<APITypes.CreateMediaFromYoutubeMutationVariables, APITypes.CreateMediaFromYoutubeMutation>;
export const importFromLink = /* GraphQL */ `mutation ImportFromLink($input: ImportFromLinkInput!) {
  importFromLink(input: $input) {
    __typename
    userId
    mediaId
    created
    updated
    title
    description
    password
    public
    noteId
    flashcardSetId
    folderId
    trash
    type
    fileType
    pages
    length
    bucket
    embedUrl
    embedCommands
    subject
    topic
    exam_v2
    examUnit
    examSection
    tags
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    verified
    transcript
    schoolId
    grade
    views
    jwt
    classId
    addedAt
    sections
    partial
    xpTracked
    isAI
  }
}
` as GeneratedMutation<APITypes.ImportFromLinkMutationVariables, APITypes.ImportFromLinkMutation>;
export const duplicateMedia = /* GraphQL */ `mutation DuplicateMedia($input: DuplicateMediaInput!) {
  duplicateMedia(input: $input) {
    __typename
    userId
    mediaId
    created
    updated
    title
    description
    password
    public
    noteId
    flashcardSetId
    folderId
    trash
    type
    fileType
    pages
    length
    bucket
    embedUrl
    embedCommands
    subject
    topic
    exam_v2
    examUnit
    examSection
    tags
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    verified
    transcript
    schoolId
    grade
    views
    jwt
    classId
    addedAt
    sections
    partial
    xpTracked
    isAI
  }
}
` as GeneratedMutation<APITypes.DuplicateMediaMutationVariables, APITypes.DuplicateMediaMutation>;
export const updateMedia = /* GraphQL */ `mutation UpdateMedia($input: UpdateMediaInput!) {
  updateMedia(input: $input) {
    __typename
    userId
    mediaId
    created
    updated
    title
    description
    password
    public
    noteId
    flashcardSetId
    folderId
    trash
    type
    fileType
    pages
    length
    bucket
    embedUrl
    embedCommands
    subject
    topic
    exam_v2
    examUnit
    examSection
    tags
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    verified
    transcript
    schoolId
    grade
    views
    jwt
    classId
    addedAt
    sections
    partial
    xpTracked
    isAI
  }
}
` as GeneratedMutation<APITypes.UpdateMediaMutationVariables, APITypes.UpdateMediaMutation>;
export const deleteMedia = /* GraphQL */ `mutation DeleteMedia($input: DeleteMediaInput!) {
  deleteMedia(input: $input) {
    __typename
    userId
    mediaId
    created
    updated
    title
    description
    password
    public
    noteId
    flashcardSetId
    folderId
    trash
    type
    fileType
    pages
    length
    bucket
    embedUrl
    embedCommands
    subject
    topic
    exam_v2
    examUnit
    examSection
    tags
    icon {
      emoji
      skinTone
    }
    rating
    ratingCount
    verified
    transcript
    schoolId
    grade
    views
    jwt
    classId
    addedAt
    sections
    partial
    xpTracked
    isAI
  }
}
` as GeneratedMutation<APITypes.DeleteMediaMutationVariables, APITypes.DeleteMediaMutation>;
export const createFlashcardSet = /* GraphQL */ `mutation CreateFlashcardSet($input: CreateFlashcardSetInput!) {
  createFlashcardSet(input: $input) {
    __typename
    userId
    flashcardSetId
    sort
    noteId
    mediaId
    position
    title
    description
    public
    password
    trash
    draft
    starred
    size
    studyFrom
    created
    updated
    quizletUrl
    answerSide
    shuffled
    flashcards {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
      termRecordedAudio
      definitionRecordedAudio
      mcOptions
      termCustomHint
      definitionCustomHint
    }
    termLanguage
    definitionLanguage
    relatedNotes
    order
    flashcardSetCode
    views
    classPublic
    subject
    verified
    schoolId
    grade
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    folderId
    rating
    ratingCount
    classId
    addedAt
    sections
    lock
    partial
    xpTracked
    studySettings {
      blockedStudyModes
      settings {
        LEARN {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          locked
          grid
          starred
          length
        }
        REVIEW {
          locked
          questionTypes
          answerSide
          starred
          position
          shuffled
          fuzzy
          reType
        }
      }
    }
    isAI
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    deadline
  }
}
` as GeneratedMutation<APITypes.CreateFlashcardSetMutationVariables, APITypes.CreateFlashcardSetMutation>;
export const createFlashcardSetV2 = /* GraphQL */ `mutation CreateFlashcardSetV2($input: CreateFlashcardSetInput!) {
  createFlashcardSetV2(input: $input) {
    item {
      __typename
      userId
      flashcardSetId
      sort
      noteId
      mediaId
      position
      title
      description
      public
      password
      trash
      draft
      starred
      size
      studyFrom
      created
      updated
      quizletUrl
      answerSide
      shuffled
      flashcards {
        flashcardId
        flashcardSetId
        userId
        term
        termAudio
        definition
        definitionAudio
        distractorIds
        questionType
        isCorrect
        draft
        trash
        disabled
        image
        secondaryImage
        edited
        created
        updated
        twoSided
        schedule
        quality
        termRecordedAudio
        definitionRecordedAudio
        mcOptions
        termCustomHint
        definitionCustomHint
      }
      termLanguage
      definitionLanguage
      relatedNotes
      order
      flashcardSetCode
      views
      classPublic
      subject
      verified
      schoolId
      grade
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      folderId
      rating
      ratingCount
      classId
      addedAt
      sections
      lock
      partial
      xpTracked
      studySettings {
        blockedStudyModes
        settings {
          LEARN {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            reType
            length
            override
          }
          TEST {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            length
          }
          SPACED {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            cardsPerSession
            intervals
          }
          MATCH {
            locked
            grid
            starred
            length
          }
          REVIEW {
            locked
            questionTypes
            answerSide
            starred
            position
            shuffled
            fuzzy
            reType
          }
        }
      }
      isAI
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      deadline
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedMutation<APITypes.CreateFlashcardSetV2MutationVariables, APITypes.CreateFlashcardSetV2Mutation>;
export const updateFlashcardSet = /* GraphQL */ `mutation UpdateFlashcardSet($input: UpdateFlashcardSetInput!) {
  updateFlashcardSet(input: $input) {
    __typename
    userId
    flashcardSetId
    sort
    noteId
    mediaId
    position
    title
    description
    public
    password
    trash
    draft
    starred
    size
    studyFrom
    created
    updated
    quizletUrl
    answerSide
    shuffled
    flashcards {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
      termRecordedAudio
      definitionRecordedAudio
      mcOptions
      termCustomHint
      definitionCustomHint
    }
    termLanguage
    definitionLanguage
    relatedNotes
    order
    flashcardSetCode
    views
    classPublic
    subject
    verified
    schoolId
    grade
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    folderId
    rating
    ratingCount
    classId
    addedAt
    sections
    lock
    partial
    xpTracked
    studySettings {
      blockedStudyModes
      settings {
        LEARN {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          locked
          grid
          starred
          length
        }
        REVIEW {
          locked
          questionTypes
          answerSide
          starred
          position
          shuffled
          fuzzy
          reType
        }
      }
    }
    isAI
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    deadline
  }
}
` as GeneratedMutation<APITypes.UpdateFlashcardSetMutationVariables, APITypes.UpdateFlashcardSetMutation>;
export const updateFlashcardSetV2 = /* GraphQL */ `mutation UpdateFlashcardSetV2($input: UpdateFlashcardSetInput!) {
  updateFlashcardSetV2(input: $input) {
    item {
      __typename
      userId
      flashcardSetId
      sort
      noteId
      mediaId
      position
      title
      description
      public
      password
      trash
      draft
      starred
      size
      studyFrom
      created
      updated
      quizletUrl
      answerSide
      shuffled
      flashcards {
        flashcardId
        flashcardSetId
        userId
        term
        termAudio
        definition
        definitionAudio
        distractorIds
        questionType
        isCorrect
        draft
        trash
        disabled
        image
        secondaryImage
        edited
        created
        updated
        twoSided
        schedule
        quality
        termRecordedAudio
        definitionRecordedAudio
        mcOptions
        termCustomHint
        definitionCustomHint
      }
      termLanguage
      definitionLanguage
      relatedNotes
      order
      flashcardSetCode
      views
      classPublic
      subject
      verified
      schoolId
      grade
      exam_v2
      examUnit
      examSection
      tags
      topic
      icon {
        emoji
        skinTone
      }
      folderId
      rating
      ratingCount
      classId
      addedAt
      sections
      lock
      partial
      xpTracked
      studySettings {
        blockedStudyModes
        settings {
          LEARN {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            reType
            length
            override
          }
          TEST {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            length
          }
          SPACED {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            cardsPerSession
            intervals
          }
          MATCH {
            locked
            grid
            starred
            length
          }
          REVIEW {
            locked
            questionTypes
            answerSide
            starred
            position
            shuffled
            fuzzy
            reType
          }
        }
      }
      isAI
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      deadline
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedMutation<APITypes.UpdateFlashcardSetV2MutationVariables, APITypes.UpdateFlashcardSetV2Mutation>;
export const deleteFlashcardSet = /* GraphQL */ `mutation DeleteFlashcardSet($input: DeleteFlashcardSetInput!) {
  deleteFlashcardSet(input: $input) {
    __typename
    userId
    flashcardSetId
    sort
    noteId
    mediaId
    position
    title
    description
    public
    password
    trash
    draft
    starred
    size
    studyFrom
    created
    updated
    quizletUrl
    answerSide
    shuffled
    flashcards {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
      termRecordedAudio
      definitionRecordedAudio
      mcOptions
      termCustomHint
      definitionCustomHint
    }
    termLanguage
    definitionLanguage
    relatedNotes
    order
    flashcardSetCode
    views
    classPublic
    subject
    verified
    schoolId
    grade
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    folderId
    rating
    ratingCount
    classId
    addedAt
    sections
    lock
    partial
    xpTracked
    studySettings {
      blockedStudyModes
      settings {
        LEARN {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          locked
          grid
          starred
          length
        }
        REVIEW {
          locked
          questionTypes
          answerSide
          starred
          position
          shuffled
          fuzzy
          reType
        }
      }
    }
    isAI
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    deadline
  }
}
` as GeneratedMutation<APITypes.DeleteFlashcardSetMutationVariables, APITypes.DeleteFlashcardSetMutation>;
export const createFlashcard = /* GraphQL */ `mutation CreateFlashcard($input: FlashcardInput!) {
  createFlashcard(input: $input) {
    flashcardId
    flashcardSetId
    userId
    term
    termAudio
    definition
    definitionAudio
    distractorIds
    questionType
    isCorrect
    draft
    trash
    disabled
    image
    secondaryImage
    edited
    created
    updated
    twoSided
    schedule
    quality
    termRecordedAudio
    definitionRecordedAudio
    mcOptions
    termCustomHint
    definitionCustomHint
  }
}
` as GeneratedMutation<APITypes.CreateFlashcardMutationVariables, APITypes.CreateFlashcardMutation>;
export const batchUpdateFlashcard = /* GraphQL */ `mutation BatchUpdateFlashcard($input: FlashcardListInput!) {
  batchUpdateFlashcard(input: $input) {
    items {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
      termRecordedAudio
      definitionRecordedAudio
      mcOptions
      termCustomHint
      definitionCustomHint
    }
    nextToken
  }
}
` as GeneratedMutation<APITypes.BatchUpdateFlashcardMutationVariables, APITypes.BatchUpdateFlashcardMutation>;
export const deleteFlashcard = /* GraphQL */ `mutation DeleteFlashcard($input: FlashcardInput!) {
  deleteFlashcard(input: $input) {
    flashcardId
    flashcardSetId
    userId
    term
    termAudio
    definition
    definitionAudio
    distractorIds
    questionType
    isCorrect
    draft
    trash
    disabled
    image
    secondaryImage
    edited
    created
    updated
    twoSided
    schedule
    quality
    termRecordedAudio
    definitionRecordedAudio
    mcOptions
    termCustomHint
    definitionCustomHint
  }
}
` as GeneratedMutation<APITypes.DeleteFlashcardMutationVariables, APITypes.DeleteFlashcardMutation>;
export const markAsRead = /* GraphQL */ `mutation MarkAsRead($input: NotificationInput!) {
  markAsRead(input: $input) {
    status
    body
  }
}
` as GeneratedMutation<APITypes.MarkAsReadMutationVariables, APITypes.MarkAsReadMutation>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification($input: NotificationInput!) {
  deleteNotification(input: $input) {
    status
    body
  }
}
` as GeneratedMutation<APITypes.DeleteNotificationMutationVariables, APITypes.DeleteNotificationMutation>;
export const updateUserDetailsV2 = /* GraphQL */ `mutation UpdateUserDetailsV2($input: UserDetailsInput!) {
  updateUserDetailsV2(input: $input) {
    item {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedMutation<APITypes.UpdateUserDetailsV2MutationVariables, APITypes.UpdateUserDetailsV2Mutation>;
export const follow = /* GraphQL */ `mutation Follow($input: FollowInput!) {
  follow(input: $input) {
    userId
    followerId
  }
}
` as GeneratedMutation<APITypes.FollowMutationVariables, APITypes.FollowMutation>;
export const followV2 = /* GraphQL */ `mutation FollowV2($input: FollowInput!) {
  followV2(input: $input) {
    item {
      userId
      followerId
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedMutation<APITypes.FollowV2MutationVariables, APITypes.FollowV2Mutation>;
export const unfollow = /* GraphQL */ `mutation Unfollow($input: UnfollowInput!) {
  unfollow(input: $input) {
    userId
    followerId
  }
}
` as GeneratedMutation<APITypes.UnfollowMutationVariables, APITypes.UnfollowMutation>;
export const removeFollower = /* GraphQL */ `mutation RemoveFollower($input: RemoveFollowerInput!) {
  removeFollower(input: $input) {
    userId
    followerId
  }
}
` as GeneratedMutation<APITypes.RemoveFollowerMutationVariables, APITypes.RemoveFollowerMutation>;
export const updateUsernameV2 = /* GraphQL */ `mutation UpdateUsernameV2($input: UpdateUsernameInput!) {
  updateUsernameV2(input: $input) {
    Email
    org
    accountType
    ID
    elID
    clID
    isSSOChecked
    timeZone
    referral
    Name
    pictureUrl
    username
    views
    birthday
    phone
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      resumes_at
      interval
      status
      platform
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      frq
      cost
      mSets
      mNotes
      tools
      assignments
      scans
      aiV
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
    signInTypes
    orgPlanType
    orgPlanCreated
    lastOrgPlanUpdated
    favTools
    lastStreakDate
    xpSyncDate
    xp
    level
    streak
    longPauseEnd
    coins
    featuredBadges
    records {
      xpEarned
      longestStreak
      badgesEarned
      coinsEarned
    }
    inventory {
      freezes
      revivals
      pauses
      icon
      features
      gamifySubExpiry
      gamifySubStoreProduct
    }
    gameBlock
    lastAdWatch
    lastTimeZoneChange
    notifSettings {
      emailSettings {
        disabled
        tokens
        reminderTime
      }
      pushSettings {
        disabled
        tokens
        reminderTime
      }
      gamifySettings {
        level
        streak
        badges
      }
    }
    stoppedNotifications
    inactiveUser
    promoCodes {
      code
      category
    }
    sentPromotions
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    orgRequests
    orgReqTime
    emailsSent
    lastSent
    isSidebarExpanded
  }
}
` as GeneratedMutation<APITypes.UpdateUsernameV2MutationVariables, APITypes.UpdateUsernameV2Mutation>;
export const viewItems = /* GraphQL */ `mutation ViewItems($input: TemporaryViewInput!) {
  viewItems(input: $input) {
    viewedItems {
      itemId
      itemType
      count
      itemOwnerId
      viewTime
    }
  }
}
` as GeneratedMutation<APITypes.ViewItemsMutationVariables, APITypes.ViewItemsMutation>;
export const bookmark = /* GraphQL */ `mutation Bookmark($input: CreateBookmarkInput!) {
  bookmark(input: $input) {
    status
    body
  }
}
` as GeneratedMutation<APITypes.BookmarkMutationVariables, APITypes.BookmarkMutation>;
export const unbookmark = /* GraphQL */ `mutation Unbookmark($input: DeleteBookmarkInput!) {
  unbookmark(input: $input) {
    status
    body
  }
}
` as GeneratedMutation<APITypes.UnbookmarkMutationVariables, APITypes.UnbookmarkMutation>;
export const createFolder = /* GraphQL */ `mutation CreateFolder($input: CreateFolderInput!) {
  createFolder(input: $input) {
    __typename
    userId
    folderId
    parentId
    name
    color
    description
    created
    updated
    notesCount
    trash
    public
    password
    icon {
      emoji
      skinTone
    }
    classId
    addedAt
    sections
    partial
  }
}
` as GeneratedMutation<APITypes.CreateFolderMutationVariables, APITypes.CreateFolderMutation>;
export const updateFolder = /* GraphQL */ `mutation UpdateFolder($input: UpdateFolderInput!) {
  updateFolder(input: $input) {
    __typename
    userId
    folderId
    parentId
    name
    color
    description
    created
    updated
    notesCount
    trash
    public
    password
    icon {
      emoji
      skinTone
    }
    classId
    addedAt
    sections
    partial
  }
}
` as GeneratedMutation<APITypes.UpdateFolderMutationVariables, APITypes.UpdateFolderMutation>;
export const deleteFolder = /* GraphQL */ `mutation DeleteFolder($input: DeleteFolderInput!) {
  deleteFolder(input: $input) {
    __typename
    userId
    folderId
    parentId
    name
    color
    description
    created
    updated
    notesCount
    trash
    public
    password
    icon {
      emoji
      skinTone
    }
    classId
    addedAt
    sections
    partial
  }
}
` as GeneratedMutation<APITypes.DeleteFolderMutationVariables, APITypes.DeleteFolderMutation>;
export const createRatingV2 = /* GraphQL */ `mutation CreateRatingV2($input: CreateRatingInput!) {
  createRatingV2(input: $input) {
    item {
      noteId
      userId
      noteOwnerId
      message
      rating
      type
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedMutation<APITypes.CreateRatingV2MutationVariables, APITypes.CreateRatingV2Mutation>;
export const updateRating = /* GraphQL */ `mutation UpdateRating($input: UpdateRatingInput!) {
  updateRating(input: $input) {
    noteId
    userId
    noteOwnerId
    message
    rating
    type
  }
}
` as GeneratedMutation<APITypes.UpdateRatingMutationVariables, APITypes.UpdateRatingMutation>;
export const createFlashcardSetViewer =
    /* GraphQL */ `mutation CreateFlashcardSetViewer($input: FlashcardSetViewerInput!) {
  createFlashcardSetViewer(input: $input) {
    userId
    flashcardSetId
    position
    starred
    length
    studyFrom
    studiedFlashcards
    order
    shuffled
    lastViewedFlashcard
    answerSide
    reviewMode
  }
}
` as GeneratedMutation<APITypes.CreateFlashcardSetViewerMutationVariables, APITypes.CreateFlashcardSetViewerMutation>;
export const updateFlashcardSetViewer =
    /* GraphQL */ `mutation UpdateFlashcardSetViewer($input: FlashcardSetViewerInput!) {
  updateFlashcardSetViewer(input: $input) {
    userId
    flashcardSetId
    position
    starred
    length
    studyFrom
    studiedFlashcards
    order
    shuffled
    lastViewedFlashcard
    answerSide
    reviewMode
  }
}
` as GeneratedMutation<APITypes.UpdateFlashcardSetViewerMutationVariables, APITypes.UpdateFlashcardSetViewerMutation>;
export const migrateFlashcardSet = /* GraphQL */ `mutation MigrateFlashcardSet($input: GetFlashcardSetInput!) {
  migrateFlashcardSet(input: $input) {
    __typename
    userId
    flashcardSetId
    sort
    noteId
    mediaId
    position
    title
    description
    public
    password
    trash
    draft
    starred
    size
    studyFrom
    created
    updated
    quizletUrl
    answerSide
    shuffled
    flashcards {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
      termRecordedAudio
      definitionRecordedAudio
      mcOptions
      termCustomHint
      definitionCustomHint
    }
    termLanguage
    definitionLanguage
    relatedNotes
    order
    flashcardSetCode
    views
    classPublic
    subject
    verified
    schoolId
    grade
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    folderId
    rating
    ratingCount
    classId
    addedAt
    sections
    lock
    partial
    xpTracked
    studySettings {
      blockedStudyModes
      settings {
        LEARN {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          locked
          grid
          starred
          length
        }
        REVIEW {
          locked
          questionTypes
          answerSide
          starred
          position
          shuffled
          fuzzy
          reType
        }
      }
    }
    isAI
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    deadline
  }
}
` as GeneratedMutation<APITypes.MigrateFlashcardSetMutationVariables, APITypes.MigrateFlashcardSetMutation>;
export const migrateUserFlashcardSets = /* GraphQL */ `mutation MigrateUserFlashcardSets($input: UserIdInput!) {
  migrateUserFlashcardSets(input: $input) {
    __typename
    userId
    flashcardSetId
    sort
    noteId
    mediaId
    position
    title
    description
    public
    password
    trash
    draft
    starred
    size
    studyFrom
    created
    updated
    quizletUrl
    answerSide
    shuffled
    flashcards {
      flashcardId
      flashcardSetId
      userId
      term
      termAudio
      definition
      definitionAudio
      distractorIds
      questionType
      isCorrect
      draft
      trash
      disabled
      image
      secondaryImage
      edited
      created
      updated
      twoSided
      schedule
      quality
      termRecordedAudio
      definitionRecordedAudio
      mcOptions
      termCustomHint
      definitionCustomHint
    }
    termLanguage
    definitionLanguage
    relatedNotes
    order
    flashcardSetCode
    views
    classPublic
    subject
    verified
    schoolId
    grade
    exam_v2
    examUnit
    examSection
    tags
    topic
    icon {
      emoji
      skinTone
    }
    folderId
    rating
    ratingCount
    classId
    addedAt
    sections
    lock
    partial
    xpTracked
    studySettings {
      blockedStudyModes
      settings {
        LEARN {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          locked
          grid
          starred
          length
        }
        REVIEW {
          locked
          questionTypes
          answerSide
          starred
          position
          shuffled
          fuzzy
          reType
        }
      }
    }
    isAI
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    deadline
  }
}
` as GeneratedMutation<APITypes.MigrateUserFlashcardSetsMutationVariables, APITypes.MigrateUserFlashcardSetsMutation>;
export const verifyStripeCheckout = /* GraphQL */ `mutation VerifyStripeCheckout($input: StripeConnectionInput!) {
  verifyStripeCheckout(input: $input) {
    Email
    org
    accountType
    ID
    elID
    clID
    isSSOChecked
    timeZone
    referral
    Name
    pictureUrl
    username
    views
    birthday
    phone
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      resumes_at
      interval
      status
      platform
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      frq
      cost
      mSets
      mNotes
      tools
      assignments
      scans
      aiV
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
    signInTypes
    orgPlanType
    orgPlanCreated
    lastOrgPlanUpdated
    favTools
    lastStreakDate
    xpSyncDate
    xp
    level
    streak
    longPauseEnd
    coins
    featuredBadges
    records {
      xpEarned
      longestStreak
      badgesEarned
      coinsEarned
    }
    inventory {
      freezes
      revivals
      pauses
      icon
      features
      gamifySubExpiry
      gamifySubStoreProduct
    }
    gameBlock
    lastAdWatch
    lastTimeZoneChange
    notifSettings {
      emailSettings {
        disabled
        tokens
        reminderTime
      }
      pushSettings {
        disabled
        tokens
        reminderTime
      }
      gamifySettings {
        level
        streak
        badges
      }
    }
    stoppedNotifications
    inactiveUser
    promoCodes {
      code
      category
    }
    sentPromotions
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    orgRequests
    orgReqTime
    emailsSent
    lastSent
    isSidebarExpanded
  }
}
` as GeneratedMutation<APITypes.VerifyStripeCheckoutMutationVariables, APITypes.VerifyStripeCheckoutMutation>;
export const requestDeleteAccount = /* GraphQL */ `mutation RequestDeleteAccount($input: DeleteAccountRequestInput!) {
  requestDeleteAccount(input: $input) {
    Email
    org
    accountType
    ID
    elID
    clID
    isSSOChecked
    timeZone
    referral
    Name
    pictureUrl
    username
    views
    birthday
    phone
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      resumes_at
      interval
      status
      platform
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      frq
      cost
      mSets
      mNotes
      tools
      assignments
      scans
      aiV
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
    signInTypes
    orgPlanType
    orgPlanCreated
    lastOrgPlanUpdated
    favTools
    lastStreakDate
    xpSyncDate
    xp
    level
    streak
    longPauseEnd
    coins
    featuredBadges
    records {
      xpEarned
      longestStreak
      badgesEarned
      coinsEarned
    }
    inventory {
      freezes
      revivals
      pauses
      icon
      features
      gamifySubExpiry
      gamifySubStoreProduct
    }
    gameBlock
    lastAdWatch
    lastTimeZoneChange
    notifSettings {
      emailSettings {
        disabled
        tokens
        reminderTime
      }
      pushSettings {
        disabled
        tokens
        reminderTime
      }
      gamifySettings {
        level
        streak
        badges
      }
    }
    stoppedNotifications
    inactiveUser
    promoCodes {
      code
      category
    }
    sentPromotions
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    orgRequests
    orgReqTime
    emailsSent
    lastSent
    isSidebarExpanded
  }
}
` as GeneratedMutation<APITypes.RequestDeleteAccountMutationVariables, APITypes.RequestDeleteAccountMutation>;
export const detectLanguage = /* GraphQL */ `mutation DetectLanguage($input: TextToSpeechInput!) {
  detectLanguage(input: $input) {
    language
    confidence
  }
}
` as GeneratedMutation<APITypes.DetectLanguageMutationVariables, APITypes.DetectLanguageMutation>;
export const textToSpeech = /* GraphQL */ `mutation TextToSpeech($input: TextToSpeechInput!) {
  textToSpeech(input: $input) {
    url
  }
}
` as GeneratedMutation<APITypes.TextToSpeechMutationVariables, APITypes.TextToSpeechMutation>;
export const deleteAudio = /* GraphQL */ `mutation DeleteAudio($input: TextToSpeechInput!) {
  deleteAudio(input: $input) {
    url
  }
}
` as GeneratedMutation<APITypes.DeleteAudioMutationVariables, APITypes.DeleteAudioMutation>;
export const createPassword = /* GraphQL */ `mutation CreatePassword($input: PasswordInput!) {
  createPassword(input: $input) {
    password
    type
    id
  }
}
` as GeneratedMutation<APITypes.CreatePasswordMutationVariables, APITypes.CreatePasswordMutation>;
export const checkPassword = /* GraphQL */ `mutation CheckPassword($input: PasswordInput!) {
  checkPassword(input: $input)
}
` as GeneratedMutation<APITypes.CheckPasswordMutationVariables, APITypes.CheckPasswordMutation>;
export const editChapter = /* GraphQL */ `mutation EditChapter($input: EditChapterInput!) {
  editChapter(input: $input) {
    status
    body
  }
}
` as GeneratedMutation<APITypes.EditChapterMutationVariables, APITypes.EditChapterMutation>;
export const createBlog = /* GraphQL */ `mutation CreateBlog($input: BlogInput) {
  createBlog(input: $input) {
    userId
    blogId
    title
    slug
    content
    summary
    created
    updated
    tags
    coverImage {
      url
      width
      height
    }
    metadata {
      title
      description
    }
    draft
    trash
    type
    pinned
  }
}
` as GeneratedMutation<APITypes.CreateBlogMutationVariables, APITypes.CreateBlogMutation>;
export const updateBlog = /* GraphQL */ `mutation UpdateBlog($input: BlogInput) {
  updateBlog(input: $input) {
    userId
    blogId
    title
    slug
    content
    summary
    created
    updated
    tags
    coverImage {
      url
      width
      height
    }
    metadata {
      title
      description
    }
    draft
    trash
    type
    pinned
  }
}
` as GeneratedMutation<APITypes.UpdateBlogMutationVariables, APITypes.UpdateBlogMutation>;
export const deleteBlog = /* GraphQL */ `mutation DeleteBlog($input: BlogInput) {
  deleteBlog(input: $input) {
    userId
    blogId
    title
    slug
    content
    summary
    created
    updated
    tags
    coverImage {
      url
      width
      height
    }
    metadata {
      title
      description
    }
    draft
    trash
    type
    pinned
  }
}
` as GeneratedMutation<APITypes.DeleteBlogMutationVariables, APITypes.DeleteBlogMutation>;
export const startFlashcardSetStudySessionRound = /* GraphQL */ `mutation StartFlashcardSetStudySessionRound(
  $input: FlashcardSetStudySessionInput!
) {
  startFlashcardSetStudySessionRound(input: $input) {
    studySession {
      userId
      itemId
      type
      created
      updated
      examDate
      unmigrated
      newCards
      nextDue
      nextNewDue
      settings {
        LEARN {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
          override
        }
        MATCH {
          grid
          starred
          length
        }
        REVIEW {
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          sorting
        }
      }
      progress {
        NEW
        LEARNING
        REVIEWING
        MASTERED
      }
      practiceTests {
        timeTaken
        timestamp
        score
      }
      syncTime
      lastView
      title
    }
    questions {
      flashcardId
      distractors
      questionType
      answerSide
      buttons
      tfIsCorrect
      progress
      spacing {
        AGAIN
        HARD
        GOOD
        EASY
      }
    }
  }
}
` as GeneratedMutation<
    APITypes.StartFlashcardSetStudySessionRoundMutationVariables,
    APITypes.StartFlashcardSetStudySessionRoundMutation
>;
export const gradeFlashcardSetStudySessionV2 = /* GraphQL */ `mutation GradeFlashcardSetStudySessionV2(
  $input: GradeFlashcardSetStudySessionInput!
) {
  gradeFlashcardSetStudySessionV2(input: $input) {
    item {
      studySession {
        userId
        itemId
        type
        created
        updated
        examDate
        unmigrated
        newCards
        nextDue
        nextNewDue
        settings {
          LEARN {
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            reType
            length
            override
          }
          TEST {
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            length
          }
          SPACED {
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            cardsPerSession
            intervals
            override
          }
          MATCH {
            grid
            starred
            length
          }
          REVIEW {
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            reType
            sorting
          }
        }
        progress {
          NEW
          LEARNING
          REVIEWING
          MASTERED
        }
        practiceTests {
          timeTaken
          timestamp
          score
        }
        syncTime
        lastView
        title
      }
      studyStates {
        userId
        flashcardId
        flashcardSetId
        created
        updated
        distractors
        mastery
        lastStudied
        blank
        sort
        nextDue
        difficulty
        interval
        eFactor
        progress
        history {
          timestamp
          timeTaken
          mode
          questionType
          selectedFlashcardId
          side
          button
          isCorrect
          answer
        }
      }
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    level
  }
}
` as GeneratedMutation<
    APITypes.GradeFlashcardSetStudySessionV2MutationVariables,
    APITypes.GradeFlashcardSetStudySessionV2Mutation
>;
export const resetStudySessionSorting =
    /* GraphQL */ `mutation ResetStudySessionSorting($input: ResetStudySessionSortingInput!) {
  resetStudySessionSorting(input: $input) {
    items {
      userId
      flashcardId
      flashcardSetId
      created
      updated
      distractors
      mastery
      lastStudied
      blank
      sort
      nextDue
      difficulty
      interval
      eFactor
      progress
      history {
        timestamp
        timeTaken
        mode
        questionType
        selectedFlashcardId
        side
        button
        isCorrect
        answer
      }
    }
    nextToken
  }
}
` as GeneratedMutation<APITypes.ResetStudySessionSortingMutationVariables, APITypes.ResetStudySessionSortingMutation>;
export const clearFlashcardSetStudySession = /* GraphQL */ `mutation ClearFlashcardSetStudySession(
  $input: ClearFlashcardSetStudySessionInput!
) {
  clearFlashcardSetStudySession(input: $input) {
    userId
    itemId
    type
    created
    updated
    examDate
    unmigrated
    newCards
    nextDue
    nextNewDue
    settings {
      LEARN {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        reType
        length
        override
      }
      TEST {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        length
      }
      SPACED {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        cardsPerSession
        intervals
        override
      }
      MATCH {
        grid
        starred
        length
      }
      REVIEW {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        reType
        sorting
      }
    }
    progress {
      NEW
      LEARNING
      REVIEWING
      MASTERED
    }
    practiceTests {
      timeTaken
      timestamp
      score
    }
    syncTime
    lastView
    title
  }
}
` as GeneratedMutation<
    APITypes.ClearFlashcardSetStudySessionMutationVariables,
    APITypes.ClearFlashcardSetStudySessionMutation
>;
export const gradeNoteTest = /* GraphQL */ `mutation GradeNoteTest($input: GradeNoteTestInput!) {
  gradeNoteTest(input: $input) {
    userId
    itemId
    type
    created
    updated
    examDate
    unmigrated
    newCards
    nextDue
    nextNewDue
    settings {
      LEARN {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        reType
        length
        override
      }
      TEST {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        length
      }
      SPACED {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        cardsPerSession
        intervals
        override
      }
      MATCH {
        grid
        starred
        length
      }
      REVIEW {
        questionTypes
        answerSide
        starred
        shuffled
        fuzzy
        reType
        sorting
      }
    }
    progress {
      NEW
      LEARNING
      REVIEWING
      MASTERED
    }
    practiceTests {
      timeTaken
      timestamp
      score
    }
    syncTime
    lastView
    title
  }
}
` as GeneratedMutation<APITypes.GradeNoteTestMutationVariables, APITypes.GradeNoteTestMutation>;
export const submitAICompletion = /* GraphQL */ `mutation SubmitAICompletion($input: SubmitAICompletionInput) {
  submitAICompletion(input: $input) {
    userId
    timestamp
    noteId
    flashcardSetId
    flashcardId
    itemId
    itemType
    id
    input
    messages {
      role
      content
    }
    output
    inputTokens
    outputTokens
    subject
    topic
    type
    timeTaken
    params
  }
}
` as GeneratedMutation<APITypes.SubmitAICompletionMutationVariables, APITypes.SubmitAICompletionMutation>;
export const updateEmail = /* GraphQL */ `mutation UpdateEmail($input: UpdateEmailInput!) {
  updateEmail(input: $input) {
    Email
    org
    accountType
    ID
    elID
    clID
    isSSOChecked
    timeZone
    referral
    Name
    pictureUrl
    username
    views
    birthday
    phone
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      resumes_at
      interval
      status
      platform
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      frq
      cost
      mSets
      mNotes
      tools
      assignments
      scans
      aiV
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
    signInTypes
    orgPlanType
    orgPlanCreated
    lastOrgPlanUpdated
    favTools
    lastStreakDate
    xpSyncDate
    xp
    level
    streak
    longPauseEnd
    coins
    featuredBadges
    records {
      xpEarned
      longestStreak
      badgesEarned
      coinsEarned
    }
    inventory {
      freezes
      revivals
      pauses
      icon
      features
      gamifySubExpiry
      gamifySubStoreProduct
    }
    gameBlock
    lastAdWatch
    lastTimeZoneChange
    notifSettings {
      emailSettings {
        disabled
        tokens
        reminderTime
      }
      pushSettings {
        disabled
        tokens
        reminderTime
      }
      gamifySettings {
        level
        streak
        badges
      }
    }
    stoppedNotifications
    inactiveUser
    promoCodes {
      code
      category
    }
    sentPromotions
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    orgRequests
    orgReqTime
    emailsSent
    lastSent
    isSidebarExpanded
  }
}
` as GeneratedMutation<APITypes.UpdateEmailMutationVariables, APITypes.UpdateEmailMutation>;
export const manuallyVerifyUser = /* GraphQL */ `mutation ManuallyVerifyUser($input: ManuallyVerifyUserInput!) {
  manuallyVerifyUser(input: $input)
}
` as GeneratedMutation<APITypes.ManuallyVerifyUserMutationVariables, APITypes.ManuallyVerifyUserMutation>;
export const createClass = /* GraphQL */ `mutation CreateClass($input: CreateClassInput!) {
  createClass(input: $input) {
    __typename
    userId
    classId
    sections {
      name
      id
      color
      timings {
        start
        end
        days
      }
    }
    members {
      userId
      role
      sections
      pending
      Name
      pictureUrl
      username
      dpa
      lastLogIn
    }
    memberCount
    pinned
    created
    updated
    name
    description
    timings {
      start
      end
      days
    }
    timezone
    org
    schoolId
    grade
    subject
    topic
    exam_v2
    examUnit
    examSection
    icon {
      emoji
      skinTone
    }
    color
    cover
    googleClassId
    fileCount
    partial
    flashcardStudySettings {
      blockedStudyModes
      settings {
        LEARN {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          locked
          grid
          starred
          length
        }
        REVIEW {
          locked
          questionTypes
          answerSide
          starred
          position
          shuffled
          fuzzy
          reType
        }
      }
    }
  }
}
` as GeneratedMutation<APITypes.CreateClassMutationVariables, APITypes.CreateClassMutation>;
export const createClassV2 = /* GraphQL */ `mutation CreateClassV2($input: CreateClassInput!) {
  createClassV2(input: $input) {
    item {
      __typename
      userId
      classId
      sections {
        name
        id
        color
        timings {
          start
          end
          days
        }
      }
      members {
        userId
        role
        sections
        pending
        Name
        pictureUrl
        username
        dpa
        lastLogIn
      }
      memberCount
      pinned
      created
      updated
      name
      description
      timings {
        start
        end
        days
      }
      timezone
      org
      schoolId
      grade
      subject
      topic
      exam_v2
      examUnit
      examSection
      icon {
        emoji
        skinTone
      }
      color
      cover
      googleClassId
      fileCount
      partial
      flashcardStudySettings {
        blockedStudyModes
        settings {
          LEARN {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            reType
            length
            override
          }
          TEST {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            length
          }
          SPACED {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            cardsPerSession
            intervals
          }
          MATCH {
            locked
            grid
            starred
            length
          }
          REVIEW {
            locked
            questionTypes
            answerSide
            starred
            position
            shuffled
            fuzzy
            reType
          }
        }
      }
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedMutation<APITypes.CreateClassV2MutationVariables, APITypes.CreateClassV2Mutation>;
export const updateClass = /* GraphQL */ `mutation UpdateClass($input: UpdateClassInput!) {
  updateClass(input: $input) {
    __typename
    userId
    classId
    sections {
      name
      id
      color
      timings {
        start
        end
        days
      }
    }
    members {
      userId
      role
      sections
      pending
      Name
      pictureUrl
      username
      dpa
      lastLogIn
    }
    memberCount
    pinned
    created
    updated
    name
    description
    timings {
      start
      end
      days
    }
    timezone
    org
    schoolId
    grade
    subject
    topic
    exam_v2
    examUnit
    examSection
    icon {
      emoji
      skinTone
    }
    color
    cover
    googleClassId
    fileCount
    partial
    flashcardStudySettings {
      blockedStudyModes
      settings {
        LEARN {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          locked
          grid
          starred
          length
        }
        REVIEW {
          locked
          questionTypes
          answerSide
          starred
          position
          shuffled
          fuzzy
          reType
        }
      }
    }
  }
}
` as GeneratedMutation<APITypes.UpdateClassMutationVariables, APITypes.UpdateClassMutation>;
export const deleteClass = /* GraphQL */ `mutation DeleteClass($input: DeleteClassInput!) {
  deleteClass(input: $input) {
    __typename
    userId
    classId
    sections {
      name
      id
      color
      timings {
        start
        end
        days
      }
    }
    members {
      userId
      role
      sections
      pending
      Name
      pictureUrl
      username
      dpa
      lastLogIn
    }
    memberCount
    pinned
    created
    updated
    name
    description
    timings {
      start
      end
      days
    }
    timezone
    org
    schoolId
    grade
    subject
    topic
    exam_v2
    examUnit
    examSection
    icon {
      emoji
      skinTone
    }
    color
    cover
    googleClassId
    fileCount
    partial
    flashcardStudySettings {
      blockedStudyModes
      settings {
        LEARN {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          locked
          grid
          starred
          length
        }
        REVIEW {
          locked
          questionTypes
          answerSide
          starred
          position
          shuffled
          fuzzy
          reType
        }
      }
    }
  }
}
` as GeneratedMutation<APITypes.DeleteClassMutationVariables, APITypes.DeleteClassMutation>;
export const addMediaS3UserTag = /* GraphQL */ `mutation AddMediaS3UserTag($input: AddMediaS3UserTagInput!) {
  addMediaS3UserTag(input: $input)
}
` as GeneratedMutation<APITypes.AddMediaS3UserTagMutationVariables, APITypes.AddMediaS3UserTagMutation>;
export const joinClass = /* GraphQL */ `mutation JoinClass($input: JoinClassInput!) {
  joinClass(input: $input) {
    __typename
    userId
    classId
    sections {
      name
      id
      color
      timings {
        start
        end
        days
      }
    }
    members {
      userId
      role
      sections
      pending
      Name
      pictureUrl
      username
      dpa
      lastLogIn
    }
    memberCount
    pinned
    created
    updated
    name
    description
    timings {
      start
      end
      days
    }
    timezone
    org
    schoolId
    grade
    subject
    topic
    exam_v2
    examUnit
    examSection
    icon {
      emoji
      skinTone
    }
    color
    cover
    googleClassId
    fileCount
    partial
    flashcardStudySettings {
      blockedStudyModes
      settings {
        LEARN {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          locked
          grid
          starred
          length
        }
        REVIEW {
          locked
          questionTypes
          answerSide
          starred
          position
          shuffled
          fuzzy
          reType
        }
      }
    }
  }
}
` as GeneratedMutation<APITypes.JoinClassMutationVariables, APITypes.JoinClassMutation>;
export const joinClassV2 = /* GraphQL */ `mutation JoinClassV2($input: JoinClassInput!) {
  joinClassV2(input: $input) {
    item {
      __typename
      userId
      classId
      sections {
        name
        id
        color
        timings {
          start
          end
          days
        }
      }
      members {
        userId
        role
        sections
        pending
        Name
        pictureUrl
        username
        dpa
        lastLogIn
      }
      memberCount
      pinned
      created
      updated
      name
      description
      timings {
        start
        end
        days
      }
      timezone
      org
      schoolId
      grade
      subject
      topic
      exam_v2
      examUnit
      examSection
      icon {
        emoji
        skinTone
      }
      color
      cover
      googleClassId
      fileCount
      partial
      flashcardStudySettings {
        blockedStudyModes
        settings {
          LEARN {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            reType
            length
            override
          }
          TEST {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            length
          }
          SPACED {
            locked
            questionTypes
            answerSide
            starred
            shuffled
            fuzzy
            cardsPerSession
            intervals
          }
          MATCH {
            locked
            grid
            starred
            length
          }
          REVIEW {
            locked
            questionTypes
            answerSide
            starred
            position
            shuffled
            fuzzy
            reType
          }
        }
      }
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedMutation<APITypes.JoinClassV2MutationVariables, APITypes.JoinClassV2Mutation>;
export const leaveClass = /* GraphQL */ `mutation LeaveClass($input: LeaveClassInput!) {
  leaveClass(input: $input) {
    __typename
    userId
    classId
    sections {
      name
      id
      color
      timings {
        start
        end
        days
      }
    }
    members {
      userId
      role
      sections
      pending
      Name
      pictureUrl
      username
      dpa
      lastLogIn
    }
    memberCount
    pinned
    created
    updated
    name
    description
    timings {
      start
      end
      days
    }
    timezone
    org
    schoolId
    grade
    subject
    topic
    exam_v2
    examUnit
    examSection
    icon {
      emoji
      skinTone
    }
    color
    cover
    googleClassId
    fileCount
    partial
    flashcardStudySettings {
      blockedStudyModes
      settings {
        LEARN {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          reType
          length
          override
        }
        TEST {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          length
        }
        SPACED {
          locked
          questionTypes
          answerSide
          starred
          shuffled
          fuzzy
          cardsPerSession
          intervals
        }
        MATCH {
          locked
          grid
          starred
          length
        }
        REVIEW {
          locked
          questionTypes
          answerSide
          starred
          position
          shuffled
          fuzzy
          reType
        }
      }
    }
  }
}
` as GeneratedMutation<APITypes.LeaveClassMutationVariables, APITypes.LeaveClassMutation>;
export const createEmbeddings = /* GraphQL */ `mutation CreateEmbeddings($input: CreateEmbeddingsInput!) {
  createEmbeddings(input: $input)
}
` as GeneratedMutation<APITypes.CreateEmbeddingsMutationVariables, APITypes.CreateEmbeddingsMutation>;
export const createExamMCQSession = /* GraphQL */ `mutation CreateExamMCQSession($input: CreateExamMCQSessionInput!) {
  createExamMCQSession(input: $input) {
    items {
      questions {
        exam
        questionId
        difficulty
        choices
        question
        reasons
        rating
        ratingCount
        examUnit
        examSection
        noteId
        flashcardSetId
        subject
        topic
        created
        updated
        url
        prompt
        numCorrect
        numQuestions
        stimuli
        stimulusGroupId
        questionGroupId
        replaced
        calc
      }
      stimuli {
        questionId
        stimulusId
        content
        stimulusType
        schema
      }
    }
    nextToken
  }
}
` as GeneratedMutation<APITypes.CreateExamMCQSessionMutationVariables, APITypes.CreateExamMCQSessionMutation>;
export const rateExamMCQ = /* GraphQL */ `mutation RateExamMCQ($input: RateExamMCQInput!) {
  rateExamMCQ(input: $input) {
    exam
    questionId
    difficulty
    choices
    question
    reasons
    rating
    ratingCount
    examUnit
    examSection
    noteId
    flashcardSetId
    subject
    topic
    created
    updated
    url
    prompt
    numCorrect
    numQuestions
    stimuli
    stimulusGroupId
    questionGroupId
    replaced
    calc
  }
}
` as GeneratedMutation<APITypes.RateExamMCQMutationVariables, APITypes.RateExamMCQMutation>;
export const rateExamFRQ = /* GraphQL */ `mutation RateExamFRQ($input: RateExamFRQInput!) {
  rateExamFRQ(input: $input) {
    exam
    questionId
    difficulty
    question
    rating
    ratingCount
    examUnit
    examSection
    noteId
    flashcardSetId
    subject
    topic
    created
    updated
    url
    prompt
    stimuli
    name
    parts {
      question
      rating
      ratingCount
      stimuli
      prefill
    }
    rubric {
      items {
        guidelines
        points
      }
    }
    calc
    starred
    frqType
  }
}
` as GeneratedMutation<APITypes.RateExamFRQMutationVariables, APITypes.RateExamFRQMutation>;
export const submitExamMCQSession = /* GraphQL */ `mutation SubmitExamMCQSession($input: SubmitExamMCQSessionInput!) {
  submitExamMCQSession(input: $input) {
    userId
    exam
    numQuestions
    numCorrect
    mastery
    numDownvotes
    unitProgress {
      examUnit
      progress {
        numQuestions
        numCorrect
        mastery
      }
    }
  }
}
` as GeneratedMutation<APITypes.SubmitExamMCQSessionMutationVariables, APITypes.SubmitExamMCQSessionMutation>;
export const referOrganizationV2 = /* GraphQL */ `mutation ReferOrganizationV2($input: ReferOrganizationInput!) {
  referOrganizationV2(input: $input) {
    item {
      org
      schoolId
      created
      updated
      name
      pictureUrl
      country
      state
      files {
        url
        name
      }
      students
      teachers
      syncTimestamp
      isDirty
      paywall {
        count
        lastSent
      }
      aliases
      stage
      referrals {
        userId
        created
        sent
        type
        email
      }
      dpa {
        name
        email
        link
        type
      }
      admins {
        userId
        schoolId
      }
      pendingAdmins {
        Email
        schoolId
      }
      settings {
        schoolId
        sharing
        subsAllowed
        ai
      }
      schoolsSettings {
        schoolId
        sharing
        subsAllowed
        ai
      }
      verifiedSchools
      subscriptions {
        schoolId
        created
        current_period_end
        studentPlan
        teacherPlan
        studentsPaidFor
        teachersPaidFor
        paidStudents
        paidTeachers
        autoSubsStudent
        autoSubsTeacher
      }
      customerId
      provider
      elDistrictId
      elIntegrationId
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedMutation<APITypes.ReferOrganizationV2MutationVariables, APITypes.ReferOrganizationV2Mutation>;
export const selectSchool = /* GraphQL */ `mutation SelectSchool($input: SelectSchoolInput!) {
  selectSchool(input: $input) {
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    organization {
      org
      schoolId
      created
      updated
      name
      pictureUrl
      country
      state
      files {
        url
        name
      }
      students
      teachers
      syncTimestamp
      isDirty
      paywall {
        count
        lastSent
      }
      aliases
      stage
      referrals {
        userId
        created
        sent
        type
        email
      }
      dpa {
        name
        email
        link
        type
      }
      admins {
        userId
        schoolId
      }
      pendingAdmins {
        Email
        schoolId
      }
      settings {
        schoolId
        sharing
        subsAllowed
        ai
      }
      schoolsSettings {
        schoolId
        sharing
        subsAllowed
        ai
      }
      verifiedSchools
      subscriptions {
        schoolId
        created
        current_period_end
        studentPlan
        teacherPlan
        studentsPaidFor
        teachersPaidFor
        paidStudents
        paidTeachers
        autoSubsStudent
        autoSubsTeacher
      }
      customerId
      provider
      elDistrictId
      elIntegrationId
    }
  }
}
` as GeneratedMutation<APITypes.SelectSchoolMutationVariables, APITypes.SelectSchoolMutation>;
export const migrateAccountContent =
    /* GraphQL */ `mutation MigrateAccountContent($input: MigrateAccountContentInput!) {
  migrateAccountContent(input: $input) {
    Email
    org
    accountType
    ID
    elID
    clID
    isSSOChecked
    timeZone
    referral
    Name
    pictureUrl
    username
    views
    birthday
    phone
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      resumes_at
      interval
      status
      platform
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      frq
      cost
      mSets
      mNotes
      tools
      assignments
      scans
      aiV
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
    signInTypes
    orgPlanType
    orgPlanCreated
    lastOrgPlanUpdated
    favTools
    lastStreakDate
    xpSyncDate
    xp
    level
    streak
    longPauseEnd
    coins
    featuredBadges
    records {
      xpEarned
      longestStreak
      badgesEarned
      coinsEarned
    }
    inventory {
      freezes
      revivals
      pauses
      icon
      features
      gamifySubExpiry
      gamifySubStoreProduct
    }
    gameBlock
    lastAdWatch
    lastTimeZoneChange
    notifSettings {
      emailSettings {
        disabled
        tokens
        reminderTime
      }
      pushSettings {
        disabled
        tokens
        reminderTime
      }
      gamifySettings {
        level
        streak
        badges
      }
    }
    stoppedNotifications
    inactiveUser
    promoCodes {
      code
      category
    }
    sentPromotions
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    orgRequests
    orgReqTime
    emailsSent
    lastSent
    isSidebarExpanded
  }
}
` as GeneratedMutation<APITypes.MigrateAccountContentMutationVariables, APITypes.MigrateAccountContentMutation>;
export const trackOrganizationPaywall =
    /* GraphQL */ `mutation TrackOrganizationPaywall($input: TrackOrganizationPaywallInput!) {
  trackOrganizationPaywall(input: $input) {
    org
    schoolId
    created
    updated
    name
    pictureUrl
    country
    state
    files {
      url
      name
    }
    students
    teachers
    syncTimestamp
    isDirty
    paywall {
      count
      lastSent
    }
    aliases
    stage
    referrals {
      userId
      created
      sent
      type
      email
    }
    dpa {
      name
      email
      link
      type
    }
    admins {
      userId
      schoolId
    }
    pendingAdmins {
      Email
      schoolId
    }
    settings {
      schoolId
      sharing
      subsAllowed
      ai
    }
    schoolsSettings {
      schoolId
      sharing
      subsAllowed
      ai
    }
    verifiedSchools
    subscriptions {
      schoolId
      created
      current_period_end
      studentPlan
      teacherPlan
      studentsPaidFor
      teachersPaidFor
      paidStudents
      paidTeachers
      autoSubsStudent
      autoSubsTeacher
    }
    customerId
    provider
    elDistrictId
    elIntegrationId
  }
}
` as GeneratedMutation<APITypes.TrackOrganizationPaywallMutationVariables, APITypes.TrackOrganizationPaywallMutation>;
export const authenticateEdLinkSSO =
    /* GraphQL */ `mutation AuthenticateEdLinkSSO($input: AuthenticateEdLinkSSOInput!) {
  authenticateEdLinkSSO(input: $input) {
    username
    password
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    didLink
  }
}
` as GeneratedMutation<APITypes.AuthenticateEdLinkSSOMutationVariables, APITypes.AuthenticateEdLinkSSOMutation>;
export const purchaseStoreProduct = /* GraphQL */ `mutation PurchaseStoreProduct($input: PurchaseStoreProductInput!) {
  purchaseStoreProduct(input: $input) {
    storeProductHistory {
      userId
      storeProductId
      current
      bought
      price
      code
      discount
      expiryTime
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
  }
}
` as GeneratedMutation<APITypes.PurchaseStoreProductMutationVariables, APITypes.PurchaseStoreProductMutation>;
export const claimBadgeReward = /* GraphQL */ `mutation ClaimBadgeReward($input: ClaimBadgeRewardInput!) {
  claimBadgeReward(input: $input) {
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
    gameError
  }
}
` as GeneratedMutation<APITypes.ClaimBadgeRewardMutationVariables, APITypes.ClaimBadgeRewardMutation>;
export const trackActivity = /* GraphQL */ `mutation TrackActivity($input: TrackActivityInput!) {
  trackActivity(input: $input) {
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    level
  }
}
` as GeneratedMutation<APITypes.TrackActivityMutationVariables, APITypes.TrackActivityMutation>;
export const applyStreakAction = /* GraphQL */ `mutation ApplyStreakAction($input: ApplyStreakActionInput!) {
  applyStreakAction(input: $input) {
    Email
    org
    accountType
    ID
    elID
    clID
    isSSOChecked
    timeZone
    referral
    Name
    pictureUrl
    username
    views
    birthday
    phone
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      resumes_at
      interval
      status
      platform
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      frq
      cost
      mSets
      mNotes
      tools
      assignments
      scans
      aiV
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
    signInTypes
    orgPlanType
    orgPlanCreated
    lastOrgPlanUpdated
    favTools
    lastStreakDate
    xpSyncDate
    xp
    level
    streak
    longPauseEnd
    coins
    featuredBadges
    records {
      xpEarned
      longestStreak
      badgesEarned
      coinsEarned
    }
    inventory {
      freezes
      revivals
      pauses
      icon
      features
      gamifySubExpiry
      gamifySubStoreProduct
    }
    gameBlock
    lastAdWatch
    lastTimeZoneChange
    notifSettings {
      emailSettings {
        disabled
        tokens
        reminderTime
      }
      pushSettings {
        disabled
        tokens
        reminderTime
      }
      gamifySettings {
        level
        streak
        badges
      }
    }
    stoppedNotifications
    inactiveUser
    promoCodes {
      code
      category
    }
    sentPromotions
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    orgRequests
    orgReqTime
    emailsSent
    lastSent
    isSidebarExpanded
  }
}
` as GeneratedMutation<APITypes.ApplyStreakActionMutationVariables, APITypes.ApplyStreakActionMutation>;
export const applyStreakActionV2 = /* GraphQL */ `mutation ApplyStreakActionV2($input: ApplyStreakActionInput!) {
  applyStreakActionV2(input: $input) {
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
    gameError
  }
}
` as GeneratedMutation<APITypes.ApplyStreakActionV2MutationVariables, APITypes.ApplyStreakActionV2Mutation>;
export const activateStoreProduct = /* GraphQL */ `mutation ActivateStoreProduct($input: ActivateStoreProductInput!) {
  activateStoreProduct(input: $input) {
    Email
    org
    accountType
    ID
    elID
    clID
    isSSOChecked
    timeZone
    referral
    Name
    pictureUrl
    username
    views
    birthday
    phone
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      resumes_at
      interval
      status
      platform
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      frq
      cost
      mSets
      mNotes
      tools
      assignments
      scans
      aiV
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
    signInTypes
    orgPlanType
    orgPlanCreated
    lastOrgPlanUpdated
    favTools
    lastStreakDate
    xpSyncDate
    xp
    level
    streak
    longPauseEnd
    coins
    featuredBadges
    records {
      xpEarned
      longestStreak
      badgesEarned
      coinsEarned
    }
    inventory {
      freezes
      revivals
      pauses
      icon
      features
      gamifySubExpiry
      gamifySubStoreProduct
    }
    gameBlock
    lastAdWatch
    lastTimeZoneChange
    notifSettings {
      emailSettings {
        disabled
        tokens
        reminderTime
      }
      pushSettings {
        disabled
        tokens
        reminderTime
      }
      gamifySettings {
        level
        streak
        badges
      }
    }
    stoppedNotifications
    inactiveUser
    promoCodes {
      code
      category
    }
    sentPromotions
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    orgRequests
    orgReqTime
    emailsSent
    lastSent
    isSidebarExpanded
  }
}
` as GeneratedMutation<APITypes.ActivateStoreProductMutationVariables, APITypes.ActivateStoreProductMutation>;
export const watchAd = /* GraphQL */ `mutation WatchAd {
  watchAd {
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
    gameError
  }
}
` as GeneratedMutation<APITypes.WatchAdMutationVariables, APITypes.WatchAdMutation>;
export const simulateStreak = /* GraphQL */ `mutation SimulateStreak($input: SimulateStreakInput!) {
  simulateStreak(input: $input) {
    Email
    org
    accountType
    ID
    elID
    clID
    isSSOChecked
    timeZone
    referral
    Name
    pictureUrl
    username
    views
    birthday
    phone
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      resumes_at
      interval
      status
      platform
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      frq
      cost
      mSets
      mNotes
      tools
      assignments
      scans
      aiV
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
    signInTypes
    orgPlanType
    orgPlanCreated
    lastOrgPlanUpdated
    favTools
    lastStreakDate
    xpSyncDate
    xp
    level
    streak
    longPauseEnd
    coins
    featuredBadges
    records {
      xpEarned
      longestStreak
      badgesEarned
      coinsEarned
    }
    inventory {
      freezes
      revivals
      pauses
      icon
      features
      gamifySubExpiry
      gamifySubStoreProduct
    }
    gameBlock
    lastAdWatch
    lastTimeZoneChange
    notifSettings {
      emailSettings {
        disabled
        tokens
        reminderTime
      }
      pushSettings {
        disabled
        tokens
        reminderTime
      }
      gamifySettings {
        level
        streak
        badges
      }
    }
    stoppedNotifications
    inactiveUser
    promoCodes {
      code
      category
    }
    sentPromotions
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    orgRequests
    orgReqTime
    emailsSent
    lastSent
    isSidebarExpanded
  }
}
` as GeneratedMutation<APITypes.SimulateStreakMutationVariables, APITypes.SimulateStreakMutation>;
export const simulateStreakActions =
    /* GraphQL */ `mutation SimulateStreakActions($input: SimulateStreakActionsInput!) {
  simulateStreakActions(input: $input) {
    Email
    org
    accountType
    ID
    elID
    clID
    isSSOChecked
    timeZone
    referral
    Name
    pictureUrl
    username
    views
    birthday
    phone
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      resumes_at
      interval
      status
      platform
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      frq
      cost
      mSets
      mNotes
      tools
      assignments
      scans
      aiV
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
    signInTypes
    orgPlanType
    orgPlanCreated
    lastOrgPlanUpdated
    favTools
    lastStreakDate
    xpSyncDate
    xp
    level
    streak
    longPauseEnd
    coins
    featuredBadges
    records {
      xpEarned
      longestStreak
      badgesEarned
      coinsEarned
    }
    inventory {
      freezes
      revivals
      pauses
      icon
      features
      gamifySubExpiry
      gamifySubStoreProduct
    }
    gameBlock
    lastAdWatch
    lastTimeZoneChange
    notifSettings {
      emailSettings {
        disabled
        tokens
        reminderTime
      }
      pushSettings {
        disabled
        tokens
        reminderTime
      }
      gamifySettings {
        level
        streak
        badges
      }
    }
    stoppedNotifications
    inactiveUser
    promoCodes {
      code
      category
    }
    sentPromotions
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    orgRequests
    orgReqTime
    emailsSent
    lastSent
    isSidebarExpanded
  }
}
` as GeneratedMutation<APITypes.SimulateStreakActionsMutationVariables, APITypes.SimulateStreakActionsMutation>;
export const removeGamifyAttributes = /* GraphQL */ `mutation RemoveGamifyAttributes {
  removeGamifyAttributes {
    Email
    org
    accountType
    ID
    elID
    clID
    isSSOChecked
    timeZone
    referral
    Name
    pictureUrl
    username
    views
    birthday
    phone
    hideSensitiveInfo
    noteSidebarWidth
    created
    lastLogIn
    lastNotified
    answerSide
    numFollowers
    numFollowing
    ref_v2
    refFrom_v2
    refList_v2
    verified
    bio
    socials {
      ig
      twitter
      tiktok
      in
      link
      spotify
    }
    cover
    schoolId
    grade
    rating
    ratingCount
    customerId
    subscriptions {
      subscriptionId
      customerId
      priceId
      productId
      created
      current_period_end
      cancel_at_period_end
      resumes_at
      interval
      status
      platform
    }
    alerts
    subscriptionType
    stripeVerifyTime
    stripeTrialTime
    cancelPromoTime
    deleteReq
    ai {
      curPrompts
      curPeriodEnd
      curTokens
      videos
      vNotes
      vSets
      mins
      pdfs
      pNotes
      pSets
      pages
      nTests
      nSets
      chats
      explain
      frq
      cost
      mSets
      mNotes
      tools
      assignments
      scans
      aiV
      history {
        periodEnd
        prompts
        tokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
      }
    }
    profileColor
    privacyAck
    blogger
    examHistory
    usernameChange
    signInType
    invites
    classes {
      enrolled {
        teacherId
        classId
        role
        sections
      }
      teacher
      invited {
        teacherId
        classId
        role
        sections
      }
    }
    migKey
    signInTypes
    orgPlanType
    orgPlanCreated
    lastOrgPlanUpdated
    favTools
    lastStreakDate
    xpSyncDate
    xp
    level
    streak
    longPauseEnd
    coins
    featuredBadges
    records {
      xpEarned
      longestStreak
      badgesEarned
      coinsEarned
    }
    inventory {
      freezes
      revivals
      pauses
      icon
      features
      gamifySubExpiry
      gamifySubStoreProduct
    }
    gameBlock
    lastAdWatch
    lastTimeZoneChange
    notifSettings {
      emailSettings {
        disabled
        tokens
        reminderTime
      }
      pushSettings {
        disabled
        tokens
        reminderTime
      }
      gamifySettings {
        level
        streak
        badges
      }
    }
    stoppedNotifications
    inactiveUser
    promoCodes {
      code
      category
    }
    sentPromotions
    autocompleteSettings {
      language
      disabled
      btnDisabled
      speed
      length
      grade
      format
      style
    }
    orgRequests
    orgReqTime
    emailsSent
    lastSent
    isSidebarExpanded
  }
}
` as GeneratedMutation<APITypes.RemoveGamifyAttributesMutationVariables, APITypes.RemoveGamifyAttributesMutation>;
export const processAsyncActions = /* GraphQL */ `mutation ProcessAsyncActions {
  processAsyncActions {
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
    gameError
  }
}
` as GeneratedMutation<APITypes.ProcessAsyncActionsMutationVariables, APITypes.ProcessAsyncActionsMutation>;
export const deleteAllNotifications = /* GraphQL */ `mutation DeleteAllNotifications {
  deleteAllNotifications {
    items {
      userId
      notificationId
      read
      created
      color
      image
      title
      message
      action
      type
    }
    nextToken
  }
}
` as GeneratedMutation<APITypes.DeleteAllNotificationsMutationVariables, APITypes.DeleteAllNotificationsMutation>;
export const toggleReadNotification =
    /* GraphQL */ `mutation ToggleReadNotification($input: ToggleReadNotificationInput) {
  toggleReadNotification(input: $input) {
    userId
    notificationId
    read
    created
    color
    image
    title
    message
    action
    type
  }
}
` as GeneratedMutation<APITypes.ToggleReadNotificationMutationVariables, APITypes.ToggleReadNotificationMutation>;
export const batchToggleReadNotifications =
    /* GraphQL */ `mutation BatchToggleReadNotifications($input: BatchNotificationInput) {
  batchToggleReadNotifications(input: $input) {
    items {
      userId
      notificationId
      read
      created
      color
      image
      title
      message
      action
      type
    }
    nextToken
  }
}
` as GeneratedMutation<
        APITypes.BatchToggleReadNotificationsMutationVariables,
        APITypes.BatchToggleReadNotificationsMutation
    >;
export const createVerifiedSchool = /* GraphQL */ `mutation CreateVerifiedSchool($input: VerifiedSchoolInput!) {
  createVerifiedSchool(input: $input) {
    type
    schoolId
    org
    verified
    name
    country
    state
    city
    updated
    address {
      streetOne
      streetTwo
      city
      county
      state
      zip
      country
    }
    mailing {
      streetOne
      streetTwo
      city
      county
      state
      zip
      country
    }
    population {
      teachers
      ft
      pt
    }
    aliases
    gradesLow
    gradesHigh
    website
    phone
    elSchoolId
  }
}
` as GeneratedMutation<APITypes.CreateVerifiedSchoolMutationVariables, APITypes.CreateVerifiedSchoolMutation>;
export const createAssignment = /* GraphQL */ `mutation CreateAssignment($input: CreateAssignmentInput!) {
  createAssignment(input: $input) {
    item {
      __typename
      userId
      assignmentId
      classId
      addedAt
      config
      files {
        itemId
        itemType
      }
      sections
      partial
      status
      deadline
      type
      settings {
        timeLimit
        standards
        grade
        maxSubmissions
        language
        inputMethods
        outputMethods
        autoGrade
        rubric {
          A {
            score
            condition
          }
          B {
            score
            condition
          }
          C {
            score
            condition
          }
          D {
            score
            condition
          }
          F {
            score
            condition
          }
        }
        helpfulness
        length
        priorKnowledge
        rules
        suggestions
        initialPrompt
      }
      created
      updated
      feedback {
        strength
        improvement
        followUp
        comments
      }
      title
      description
      instructions
      image
      icon {
        emoji
        skinTone
      }
      public
      password
      trash
      folderId
      subject
      topic
      numCompleted
      views
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedMutation<APITypes.CreateAssignmentMutationVariables, APITypes.CreateAssignmentMutation>;
export const updateAssignment = /* GraphQL */ `mutation UpdateAssignment($input: UpdateAssignmentInput!) {
  updateAssignment(input: $input) {
    item {
      __typename
      userId
      assignmentId
      classId
      addedAt
      config
      files {
        itemId
        itemType
      }
      sections
      partial
      status
      deadline
      type
      settings {
        timeLimit
        standards
        grade
        maxSubmissions
        language
        inputMethods
        outputMethods
        autoGrade
        rubric {
          A {
            score
            condition
          }
          B {
            score
            condition
          }
          C {
            score
            condition
          }
          D {
            score
            condition
          }
          F {
            score
            condition
          }
        }
        helpfulness
        length
        priorKnowledge
        rules
        suggestions
        initialPrompt
      }
      created
      updated
      feedback {
        strength
        improvement
        followUp
        comments
      }
      title
      description
      instructions
      image
      icon {
        emoji
        skinTone
      }
      public
      password
      trash
      folderId
      subject
      topic
      numCompleted
      views
    }
    user {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    badges {
      userId
      badge
      current
      claimed
      unclaimed
      earnedAt
      newlyEarned
    }
    dailyActivity {
      userId
      activityDate
      xp
      items {
        itemId
        xp
        type
      }
      streakAction
      badgesEarned
      coinsEarned
      asyncActions
      actionMap
    }
    level
  }
}
` as GeneratedMutation<APITypes.UpdateAssignmentMutationVariables, APITypes.UpdateAssignmentMutation>;
export const deleteAssignment = /* GraphQL */ `mutation DeleteAssignment($input: DeleteAssignmentInput!) {
  deleteAssignment(input: $input) {
    __typename
    userId
    assignmentId
    classId
    addedAt
    config
    files {
      itemId
      itemType
    }
    sections
    partial
    status
    deadline
    type
    settings {
      timeLimit
      standards
      grade
      maxSubmissions
      language
      inputMethods
      outputMethods
      autoGrade
      rubric {
        A {
          score
          condition
        }
        B {
          score
          condition
        }
        C {
          score
          condition
        }
        D {
          score
          condition
        }
        F {
          score
          condition
        }
      }
      helpfulness
      length
      priorKnowledge
      rules
      suggestions
      initialPrompt
    }
    created
    updated
    feedback {
      strength
      improvement
      followUp
      comments
    }
    title
    description
    instructions
    image
    icon {
      emoji
      skinTone
    }
    public
    password
    trash
    folderId
    subject
    topic
    numCompleted
    views
  }
}
` as GeneratedMutation<APITypes.DeleteAssignmentMutationVariables, APITypes.DeleteAssignmentMutation>;
export const createAssignmentSession =
    /* GraphQL */ `mutation CreateAssignmentSession($input: CreateAssignmentSessionInput!) {
  createAssignmentSession(input: $input) {
    assignmentId
    sessionId
    userId
    status
    created
    updated
    submitted
    duration
    score
    rubric
    feedback {
      strength
      improvement
      followUp
      comments
    }
  }
}
` as GeneratedMutation<APITypes.CreateAssignmentSessionMutationVariables, APITypes.CreateAssignmentSessionMutation>;
export const updateAssignmentSession =
    /* GraphQL */ `mutation UpdateAssignmentSession($input: UpdateAssignmentSessionInput!) {
  updateAssignmentSession(input: $input) {
    assignmentId
    sessionId
    userId
    status
    created
    updated
    submitted
    duration
    score
    rubric
    feedback {
      strength
      improvement
      followUp
      comments
    }
  }
}
` as GeneratedMutation<APITypes.UpdateAssignmentSessionMutationVariables, APITypes.UpdateAssignmentSessionMutation>;
export const createAssignmentFields =
    /* GraphQL */ `mutation CreateAssignmentFields($input: CreateAssignmentFieldsInput!) {
  createAssignmentFields(input: $input) {
    title
    description
    instructions
    subject
    topic
    settings {
      timeLimit
      standards
      grade
      maxSubmissions
      language
      inputMethods
      outputMethods
      autoGrade
      rubric {
        A {
          score
          condition
        }
        B {
          score
          condition
        }
        C {
          score
          condition
        }
        D {
          score
          condition
        }
        F {
          score
          condition
        }
      }
      helpfulness
      length
      priorKnowledge
      rules
      suggestions
      initialPrompt
    }
  }
}
` as GeneratedMutation<APITypes.CreateAssignmentFieldsMutationVariables, APITypes.CreateAssignmentFieldsMutation>;
export const updateAssignmentFields =
    /* GraphQL */ `mutation UpdateAssignmentFields($input: UpdateAssignmentFieldsInput!) {
  updateAssignmentFields(input: $input) {
    title
    description
    instructions
    subject
    topic
    settings {
      timeLimit
      standards
      grade
      maxSubmissions
      language
      inputMethods
      outputMethods
      autoGrade
      rubric {
        A {
          score
          condition
        }
        B {
          score
          condition
        }
        C {
          score
          condition
        }
        D {
          score
          condition
        }
        F {
          score
          condition
        }
      }
      helpfulness
      length
      priorKnowledge
      rules
      suggestions
      initialPrompt
    }
  }
}
` as GeneratedMutation<APITypes.UpdateAssignmentFieldsMutationVariables, APITypes.UpdateAssignmentFieldsMutation>;
export const _unusedSchoolApi = /* GraphQL */ `mutation _unusedSchoolApi($input: UnusedInputs) {
  _unusedSchoolApi(input: $input) {
    verifiedSchool {
      type
      schoolId
      org
      verified
      name
      country
      state
      city
      updated
      address {
        streetOne
        streetTwo
        city
        county
        state
        zip
        country
      }
      mailing {
        streetOne
        streetTwo
        city
        county
        state
        zip
        country
      }
      population {
        teachers
        ft
        pt
      }
      aliases
      gradesLow
      gradesHigh
      website
      phone
      elSchoolId
    }
    classPermission
    emailType
    pushTemplate {
      color
      image
      title
      message
      action
    }
    emailTemplate {
      subject
      body
      type
    }
    promoNotificationCategory
    scheduledEvent {
      eventTime
      eventId
      userId
      email
      type
      stage
      itemId
      itemType
      pushTemplate {
        color
        image
        title
        message
        action
      }
      emailTemplate {
        subject
        body
        type
      }
      repeatTime
      params
    }
    view {
      ID
      type
      time
      count
    }
    assignmentFeedbackWithScore {
      score
      feedback {
        strength
        improvement
        followUp
        comments
      }
    }
    flaggedMessage {
      org
      userId
      sessionId
      messageId
      created
      content
      contentFlag
      sender
      attachments {
        url
        parsed
        type
      }
    }
    scanHistory {
      userId
      scanId
      created
      updated
      subject
      topic
      context
      imageDescription
      relatedTopics
      questions {
        text
        type
        choices
        answer {
          text
          steps
        }
      }
      pinned
      summary
      videoResult
    }
  }
}
` as GeneratedMutation<APITypes._unusedSchoolApiMutationVariables, APITypes._unusedSchoolApiMutation>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    org
    schoolId
    created
    updated
    name
    pictureUrl
    country
    state
    files {
      url
      name
    }
    students
    teachers
    syncTimestamp
    isDirty
    paywall {
      count
      lastSent
    }
    aliases
    stage
    referrals {
      userId
      created
      sent
      type
      email
    }
    dpa {
      name
      email
      link
      type
    }
    admins {
      userId
      schoolId
    }
    pendingAdmins {
      Email
      schoolId
    }
    settings {
      schoolId
      sharing
      subsAllowed
      ai
    }
    schoolsSettings {
      schoolId
      sharing
      subsAllowed
      ai
    }
    verifiedSchools
    subscriptions {
      schoolId
      created
      current_period_end
      studentPlan
      teacherPlan
      studentsPaidFor
      teachersPaidFor
      paidStudents
      paidTeachers
      autoSubsStudent
      autoSubsTeacher
    }
    customerId
    provider
    elDistrictId
    elIntegrationId
  }
}
` as GeneratedMutation<APITypes.UpdateOrganizationMutationVariables, APITypes.UpdateOrganizationMutation>;
export const updateOrgMembers = /* GraphQL */ `mutation UpdateOrgMembers($input: UpdateOrgMembersInput!) {
  updateOrgMembers(input: $input) {
    items {
      Email
      org
      accountType
      ID
      elID
      clID
      isSSOChecked
      timeZone
      referral
      Name
      pictureUrl
      username
      views
      birthday
      phone
      hideSensitiveInfo
      noteSidebarWidth
      created
      lastLogIn
      lastNotified
      answerSide
      numFollowers
      numFollowing
      ref_v2
      refFrom_v2
      refList_v2
      verified
      bio
      socials {
        ig
        twitter
        tiktok
        in
        link
        spotify
      }
      cover
      schoolId
      grade
      rating
      ratingCount
      customerId
      subscriptions {
        subscriptionId
        customerId
        priceId
        productId
        created
        current_period_end
        cancel_at_period_end
        resumes_at
        interval
        status
        platform
      }
      alerts
      subscriptionType
      stripeVerifyTime
      stripeTrialTime
      cancelPromoTime
      deleteReq
      ai {
        curPrompts
        curPeriodEnd
        curTokens
        videos
        vNotes
        vSets
        mins
        pdfs
        pNotes
        pSets
        pages
        nTests
        nSets
        chats
        explain
        frq
        cost
        mSets
        mNotes
        tools
        assignments
        scans
        aiV
        history {
          periodEnd
          prompts
          tokens
          videos
          vNotes
          vSets
          mins
          pdfs
          pNotes
          pSets
          pages
          nTests
          nSets
          chats
          explain
          frq
          cost
          mSets
          mNotes
          tools
          assignments
          scans
          aiV
        }
      }
      profileColor
      privacyAck
      blogger
      examHistory
      usernameChange
      signInType
      invites
      classes {
        enrolled {
          teacherId
          classId
          role
          sections
        }
        teacher
        invited {
          teacherId
          classId
          role
          sections
        }
      }
      migKey
      signInTypes
      orgPlanType
      orgPlanCreated
      lastOrgPlanUpdated
      favTools
      lastStreakDate
      xpSyncDate
      xp
      level
      streak
      longPauseEnd
      coins
      featuredBadges
      records {
        xpEarned
        longestStreak
        badgesEarned
        coinsEarned
      }
      inventory {
        freezes
        revivals
        pauses
        icon
        features
        gamifySubExpiry
        gamifySubStoreProduct
      }
      gameBlock
      lastAdWatch
      lastTimeZoneChange
      notifSettings {
        emailSettings {
          disabled
          tokens
          reminderTime
        }
        pushSettings {
          disabled
          tokens
          reminderTime
        }
        gamifySettings {
          level
          streak
          badges
        }
      }
      stoppedNotifications
      inactiveUser
      promoCodes {
        code
        category
      }
      sentPromotions
      autocompleteSettings {
        language
        disabled
        btnDisabled
        speed
        length
        grade
        format
        style
      }
      orgRequests
      orgReqTime
      emailsSent
      lastSent
      isSidebarExpanded
    }
    nextToken
  }
}
` as GeneratedMutation<APITypes.UpdateOrgMembersMutationVariables, APITypes.UpdateOrgMembersMutation>;
export const updateScanHistory = /* GraphQL */ `mutation UpdateScanHistory($input: UpdateScanHistoryInput!) {
  updateScanHistory(input: $input) {
    userId
    scanId
    created
    updated
    subject
    topic
    context
    imageDescription
    relatedTopics
    questions {
      text
      type
      choices
      answer {
        text
        steps
      }
    }
    pinned
    summary
    videoResult
  }
}
` as GeneratedMutation<APITypes.UpdateScanHistoryMutationVariables, APITypes.UpdateScanHistoryMutation>;
export const deleteScanHistory = /* GraphQL */ `mutation DeleteScanHistory($input: DeleteScanHistoryInput!) {
  deleteScanHistory(input: $input)
}
` as GeneratedMutation<APITypes.DeleteScanHistoryMutationVariables, APITypes.DeleteScanHistoryMutation>;
export const answerTutorialGenerator =
    /* GraphQL */ `mutation AnswerTutorialGenerator($input: AnswerTutorialGeneratorInput!) {
  answerTutorialGenerator(input: $input)
}
` as GeneratedMutation<APITypes.AnswerTutorialGeneratorMutationVariables, APITypes.AnswerTutorialGeneratorMutation>;
export const submitExamSession = /* GraphQL */ `mutation SubmitExamSession($input: SubmitExamSessionInput!) {
  submitExamSession(input: $input) {
    userId
    historyId
    exam
    type
    sections {
      name
      time
      breakdown {
        name
        type
        mcqResult {
          items {
            questionId
            selectedAnswer
            isCorrect
          }
        }
        frqResult {
          items {
            questionId
            partAnswers {
              answer
              earnedScore
              possibleScore
              feedback
              attachments {
                url
                parsed
                type
              }
            }
          }
        }
      }
    }
    mcqMetrics
    frqMetrics
    score
    created
    updated
  }
}
` as GeneratedMutation<APITypes.SubmitExamSessionMutationVariables, APITypes.SubmitExamSessionMutation>;
export const deleteExamHistory = /* GraphQL */ `mutation DeleteExamHistory($input: DeleteExamHistoryInput!) {
  deleteExamHistory(input: $input) {
    userId
    historyId
    exam
    type
    sections {
      name
      time
      breakdown {
        name
        type
        mcqResult {
          items {
            questionId
            selectedAnswer
            isCorrect
          }
        }
        frqResult {
          items {
            questionId
            partAnswers {
              answer
              earnedScore
              possibleScore
              feedback
              attachments {
                url
                parsed
                type
              }
            }
          }
        }
      }
    }
    mcqMetrics
    frqMetrics
    score
    created
    updated
  }
}
` as GeneratedMutation<APITypes.DeleteExamHistoryMutationVariables, APITypes.DeleteExamHistoryMutation>;
export const createMockExamSession =
    /* GraphQL */ `mutation CreateMockExamSession($input: CreateMockExamSessionInput!) {
  createMockExamSession(input: $input) {
    max
    scores
    sections {
      name
      time
      sectionPoints
      sectionTotal
      breakdown {
        name
        type
        points
        total
        frqType
        mcqQuestions {
          questions {
            exam
            questionId
            difficulty
            choices
            question
            reasons
            rating
            ratingCount
            examUnit
            examSection
            noteId
            flashcardSetId
            subject
            topic
            created
            updated
            url
            prompt
            numCorrect
            numQuestions
            stimuli
            stimulusGroupId
            questionGroupId
            replaced
            calc
          }
          stimuli {
            questionId
            stimulusId
            content
            stimulusType
            schema
          }
        }
        frqQuestions {
          exam
          questionId
          difficulty
          question
          rating
          ratingCount
          examUnit
          examSection
          noteId
          flashcardSetId
          subject
          topic
          created
          updated
          url
          prompt
          stimuli {
            questionId
            stimulusId
            content
            stimulusType
            schema
          }
          name
          parts {
            question
            rating
            ratingCount
            stimuli {
              questionId
              stimulusId
              content
              stimulusType
              schema
            }
            prefill
          }
          rubric {
            items {
              guidelines
              points
            }
          }
          starred
          calc
          frqType
        }
      }
    }
  }
}
` as GeneratedMutation<APITypes.CreateMockExamSessionMutationVariables, APITypes.CreateMockExamSessionMutation>;
